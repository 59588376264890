import axios from "axios";
import {IChapterMod, IMod, IModRecord} from "@backend/mongoose.gen";

export const BASE_URL: string = process.env.NODE_ENV === 'development' ? 'http://localhost:8020' : ''

export const getAvailableMods = (requestBody: object, successFunction: any, failureFunction: any): void => {
    const url: string = `${BASE_URL}/mods/get-available`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.data)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const getAllChapters = (successFunction: any, failureFunction: any): void => {
    const url: string = `${BASE_URL}/mods/chapters/get-all`

    axios.post(url, null)
        .then(response => {
            successFunction(response.data.data)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const getUnavailableChapters = (successFunction: any, failureFunction: any): void => {
    const url: string = `${BASE_URL}/mods/chapters/get-unavailable`

    axios.post(url, null)
        .then(response => {
            successFunction(response.data.data)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const saveSpellingMod = (requestBody: IMod, successFunction: any, failureFunction: any): void => {
    const url: string = `${BASE_URL}/mods/add-spelling`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.data)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const saveChapterMod = (requestBody: IChapterMod, successFunction: (result: any) => void, failureFunction: any): void => {
    const url: string = `${BASE_URL}/mods/add-chapter`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const startChapterTest = (requestBody: IModRecord, successFunction: (result: any) => void, failureFunction: any): void => {
    const url: string = `${BASE_URL}/test/chapter/start`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.id)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const completeChapterTest = (chapterTest_ID: string, successFunction: (result: any) => void, failureFunction: any): void => {
    if (!chapterTest_ID) {
        return
    }

    const url: string = `${BASE_URL}/test/chapter/complete`

    axios.post(url, {
        id: chapterTest_ID
    })
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const startChapterStudy = (requestBody: IModRecord, successFunction: (result: any) => void, failureFunction: any): void => {
    const url: string = `${BASE_URL}/study/chapter/start`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.id)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const completeChapterStudy = (chapterStudy_ID: string, successFunction: (result: any) => void, failureFunction: any): void => {
    if (!chapterStudy_ID) {
        return
    }

    const url: string = `${BASE_URL}/study/chapter/complete`

    axios.post(url, {
        id: chapterStudy_ID
    })
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const startSpellingTest = (requestBody: IModRecord, successFunction: (result: any) => void, failureFunction: any): void => {
    const url: string = `${BASE_URL}/test/spelling/start`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.id)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const completeSpellingTest = (spellingTest_ID: string, successFunction: (result: any) => void, failureFunction: any): void => {
    if (!spellingTest_ID) {
        return
    }

    const url: string = `${BASE_URL}/test/spelling/complete`

    axios.post(url, {
        id: spellingTest_ID
    })
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const startSpellingStudy = (requestBody: IModRecord, successFunction: (result: any) => void, failureFunction: any): void => {
    const url: string = `${BASE_URL}/study/spelling/start`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.id)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const completeSpellingStudy = (spellingStudy_ID: string, successFunction: (result: any) => void, failureFunction: any): void => {
    if (!spellingStudy_ID) {
        return
    }

    const url: string = `${BASE_URL}/study/spelling/complete`

    axios.post(url, {
        id: spellingStudy_ID
    })
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}


export const updateChapterMod = (requestBody: IChapterMod, successFunction: any, failureFunction: any): void => {
    const url: string = `${BASE_URL}/mods/update-chapter`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.data)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const getMod = (id: string, successFunction: (result: IChapterMod | IMod) => void, failureFunction: any): void => {
    const url: string = `${BASE_URL}/id/${id}`

    axios.post(url, {})
        .then(response => {
            successFunction(response.data.modData)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}
