import {configureStore} from '@reduxjs/toolkit'
import chapterReducer from 'src/slices/chapterSlice'
import pagesReducer from 'src/slices/pageSlice'
import wordsReducer from 'src/slices/wordsSlice'
import spellingAssessmentReducer from 'src/slices/spellingAssessmentSlice'
import chapterAssessmentReducer from "src/slices/chapterAssessmentSlice";
import settingsReducer from "src/slices/settingsSlice";
import sharedReducer from "src/slices/sharedSlice";

export const store = configureStore({
    reducer: {
        chapter: chapterReducer,
        chapterAssessment: chapterAssessmentReducer,
        pages: pagesReducer,
        settings: settingsReducer,
        words: wordsReducer,
        spellingAssessment: spellingAssessmentReducer,
        shared: sharedReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch