import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "src/Home";
import ImportWords from "src/components/Spelling/ImportWords";
import {Paths} from "src/utils/constants";
import ImportChapter from "src/components/Chapter/ImportChapter";
import AllChapters from "src/components/Chapter/AllChapters";
import UnavailableChapters from "src/components/Chapter/UnavailableChapters";
import {AssessmentHomePage} from "src/components/Spelling/AssessmentHomePage";
import {StudyWords} from "src/components/Spelling/StudyWords";
import ModOrPageNotFound, {NotFoundType} from "src/components/Shared/ModOrPageNotFound";
import {ChooseCorrectAnswerInChapter} from "src/components/Chapter/ChooseCorrectAnswerInChapter";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path={Paths.Home} element={<Home/>}/>
                <Route path={Paths.ImportChapter} element={<ImportChapter/>}/>
                <Route path={Paths.ImportWords} element={<ImportWords/>}/>
                <Route path={Paths.AllChapters} element={<AllChapters/>}/>
                <Route path={Paths.UnavailableChapters} element={<UnavailableChapters/>}/>
                <Route path="/study/chapter/id/:id" element={<ChooseCorrectAnswerInChapter/>}/>
                <Route path="/study/words/id/:id" element={<StudyWords/>}/>
                <Route path="/test/id/:id" element={<AssessmentHomePage/>}/>
                <Route path="*" element={<ModOrPageNotFound notFoundType={NotFoundType.Page}/>}/>
            </Routes>
        </Router>
    )
};

export default App;