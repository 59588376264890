import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import BulkInsert, {BulkInsertType} from "src/components/Chapter/BulkInsert";
import {FC, useState} from "react";
import {IconButton, List, ListItem} from "@mui/joy";
import {KeyboardArrowDown} from "@mui/icons-material";
import Typography from "@mui/joy/Typography";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const BulkInsertContainer: FC = () => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState(0);

    const BulkInsertTab = (props: TabPanelProps) => {
        {
            if (props.value !== props.index)
                return null

            if (props.value === 0) {
                return <BulkInsert typeOfInsert={BulkInsertType.MCQ}/>
            }

            if (props.value === 1) {
                return <BulkInsert typeOfInsert={BulkInsertType.QA}/>
            }

            return null
        }
    };
    const tabProps = (index: number) => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <List size="sm"
              sx={() => ({
                  'marginLeft': '20px',
                  'marginTop': '0px',
                  'marginBottom': '0px',
                  '--ListItem-paddingRight': '16px',
                  '--ListItem-paddingLeft': '21px',
                  '--ListItem-startActionTranslateX': '-50%',
              })}
        >
            <ListItem
                nested
                sx={{my: 0}}
                startAction={
                    <IconButton
                        variant="plain"
                        size="sm"
                        color="neutral"
                        onClick={() => setOpen(!open)}
                    >
                        <KeyboardArrowDown
                            sx={{transform: open ? 'initial' : 'rotate(-90deg)'}}
                        />
                    </IconButton>
                }
            >
                <ListItem>
                    <Typography
                        level="inherit"
                        sx={{
                            fontWeight: open ? 'bold' : undefined,
                            color: open ? 'text.primary' : 'inherit',
                        }}
                    >
                        Paragraph: Bulk Insert (MCQs and QAs)
                    </Typography>
                </ListItem>
                {open && (

                    <Box sx={{width: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="MCQs Bulk Insert"  {...tabProps(0)} />
                                <Tab label="QAs Bulk Insert" {...tabProps(1)} />
                            </Tabs>
                        </Box>
                        <BulkInsertTab value={value} index={0}/>
                        <BulkInsertTab value={value} index={1}/>
                    </Box>
                )}
            </ListItem>
        </List>
    )
}
