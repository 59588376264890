import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Link, useLocation} from "react-router-dom";
import Typography from '@mui/joy/Typography';
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {Page, update} from "src/slices/pageSlice";
import {pauseCountdown, resumeCountdown} from "src/slices/wordsSlice";
import {RootState} from "src/app/store";
import {SMModalDialog} from "src/components/SMModalDialog";
import {Paths} from "src/utils/constants";
import {useSharedMgr} from "src/hooks/useSharedMgr";

export const Header: React.FC = () => {
    const [showCloseDialog, setShowCloseDialog] = React.useState<boolean>(false);

    const [dialogMsg, setDialogMsg] = useState('')
    const [dialogConfirmButtonMsg, setDialogConfirmButtonMsg] = useState('')

    const location = useLocation();
    const currentPath = location.pathname;

    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const currentPage = useAppSelector((state: RootState) => state.pages.value);

    const {stopSpeaking} = useSharedMgr();

    return (
        <div style={{position: "sticky", top: 0, width: "100%"}}>
            <Typography level="h1" fontWeight="lg" fontSize="xl" padding="10px" bgcolor="blue"
                        sx={
                            {
                                color: "palegreen"
                            }
                        }
            >
                {
                    (() => {
                        if (currentPath === Paths.ImportWords || currentPath === Paths.ImportChapter) return (
                            <div style={{width: "fit-content", cursor: "pointer"}}>
                                <Link to="/">
                                    StudyMods
                                </Link>
                            </div>
                        )

                        return (
                            <div style={{width: "fit-content", cursor: "pointer"}}
                                 onClick={() => {
                                     if (currentPage === Page.SHOW_WORD || currentPage === Page.ANSWER_QUESTION) {
                                         setDialogMsg("Are you sure you want to leave the assessment?")
                                         setDialogConfirmButtonMsg("Leave Assessment")
                                         appDispatch(pauseCountdown());
                                         setShowCloseDialog(true)
                                     } else if (currentPage === Page.STUDY_WORDS || currentPage === Page.CHOOSE_CORRECT_ANSWER_IN_CHAPTER || currentPage === Page.TYPE_CORRECT_ANSWER_IN_CHAPTER || currentPage === Page.CHOOSE_CORRECT_SPELLING) {
                                         setDialogMsg("Are you sure you want to exit the study session?")
                                         setDialogConfirmButtonMsg("Exit Study Session")
                                         setShowCloseDialog(true)
                                     } else {
                                         appDispatch(update(Page.HOME));
                                     }
                                 }
                                 }
                            >
                                StudyMods
                            </div>
                        )
                    })()
                }
            </Typography>
            <SMModalDialog
                showDialog={showCloseDialog}
                cancelAction={() => {
                    setShowCloseDialog(false)

                    if (currentPage === Page.SHOW_WORD || currentPage === Page.ANSWER_QUESTION) {
                        appDispatch(resumeCountdown())
                    }
                }}
                confirmationAction={() => {
                    setShowCloseDialog(false)

                    if (currentPage === Page.SHOW_WORD || currentPage === Page.ANSWER_QUESTION) {
                        appDispatch(resumeCountdown())
                    }

                    stopSpeaking()
                    navigate(Paths.Home)
                    appDispatch(update(Page.HOME));
                }}
                assessmentPageTitle={"Please Confirm"}
                bodyMsg={dialogMsg}
                confirmBtnMsg={dialogConfirmButtonMsg}
            />
        </div>
    )
}