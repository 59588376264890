import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type IChapterAssessment = {
    chapter_id: string,
    paragraph_id: string,
    qa_id: string;
    question: string;
    correct_answer: string;
    incorrect_answer?: string;
    typo_in_answer?: string;
    duration: number
}

export interface ChapterAssessmentState {
    data: IChapterAssessment[]
}

const initialState: ChapterAssessmentState = {
    data: [],
}

export const chapterAssessmentSlice = createSlice({
    name: "chapterAssessment",
    initialState,
    reducers: {
        resetChapterAssessment(state) {
            return {
                ...state,
                data: []
            }
        },
        saveChapterAssessment(state, action: PayloadAction<IChapterAssessment>) {
            return {
                ...state,
                data: state.data.concat(action.payload)
            };
        }
    }
})

export const {resetChapterAssessment, saveChapterAssessment} = chapterAssessmentSlice.actions

export default chapterAssessmentSlice.reducer