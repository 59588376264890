import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Sheet, Table} from "@mui/joy";
import {getAllChapters} from "src/utils/api-service";
import {IChapterMod} from "@backend/mongoose.gen";
import Typography from "@mui/joy/Typography";
import {Paths} from "src/utils/constants";
import {useAppDispatch} from "src/app/hooks";
import {
    addChapter
} from "src/slices/chapterSlice";

export const AllChapters: React.FC = () => {
    const [allChapters, setAllChapters] = React.useState<IChapterMod[]>();

    const navigate = useNavigate();
    const appDispatch = useAppDispatch();

    const allChaptersRetrieved = (unavailableChaptersData: IChapterMod[]) => {
        setAllChapters(unavailableChaptersData);
    };

    const retrievalFailure = (error: string) => {
        console.log('error: ', error)
    };

    useEffect(() => {
        if (prompt("Do the needful:") !== process.env.REACT_APP_KEY) {
            navigate(Paths.Home)
        }
        
        getAllChapters(allChaptersRetrieved, retrievalFailure)
    }, []);

    const editChapter = (chapter: IChapterMod) => {
        appDispatch(addChapter(chapter))
        navigate(Paths.ImportChapter)
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
            }}
        >
            <Typography level="h1" fontWeight="lg" fontSize="xxx-large"
                        sx={{color: "white", paddingBottom: "5px"}}>
                All Chapters
            </Typography>
            <Sheet sx={{height: '100%', overflow: 'auto', marginLeft: '20px', marginRight: '20px'}}>
                <Table
                    aria-label="table with sticky header"
                    stickyHeader
                    stickyFooter
                >
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Title</th>
                            <th>Grade</th>
                            <th>Year</th>
                            <th>Org</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {allChapters?.map((unavailableChapter) => (
                            <tr key={unavailableChapter._id.toString()}>
                                <td>{unavailableChapter.subject}</td>
                                <td>{unavailableChapter.title}</td>
                                <td>{unavailableChapter.grade}</td>
                                <td>{unavailableChapter.academic_year}</td>
                                <td>{unavailableChapter.org}</td>
                                <td>
                                    <Box sx={{display: 'flex', gap: 1}}>
                                        <Button size="sm" variant="soft" color="primary"
                                                onClick={
                                                    () => editChapter(unavailableChapter)
                                                }
                                        >
                                            Edit
                                        </Button>
                                    </Box>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={6} style={{textAlign: 'center'}}>
                            Count: {allChapters?.length}
                        </td>
                    </tr>
                    </tfoot>
                </Table>
            </Sheet>
        </Box>
    )
}

export default AllChapters