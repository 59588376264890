import React, {useEffect, useState} from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from "@mui/joy/Typography";
import {Radio, RadioGroup} from "@mui/joy";
import {formatDate, newObjectId, popParagraphData} from "src/utils/functions";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {Page, setAssessmentPageTitle, setAssessmentPageType, update} from "src/slices/pageSlice";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {resetSpellingAssessment} from "src/slices/spellingAssessmentSlice";
import {resizeButtonSX} from "src/utils/ui";
import {RootState} from "src/app/store";
import {saveSubmitWhenEnterKeyIsPressed} from "src/slices/settingsSlice";
import {
    saveSelectedSpellingInfo,
    setAssessmentCompletedBeforeStudying,
    updateVoiceGender,
    VoiceGender
} from "src/slices/wordsSlice";
import {ModTypes} from "src/utils/constants";
import {resetChapterAssessment} from "src/slices/chapterAssessmentSlice";
import {useParams} from 'react-router-dom';
import {getMod, startChapterTest, startSpellingTest} from "src/utils/api-service";
import {IChapterMod, IMod, IModRecord} from "@backend/mongoose.gen";
import Title from "src/components/Shared/Title";
import Description from "src/components/Shared/Description";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import ModOrPageNotFound, {NotFoundType} from "src/components/Shared/ModOrPageNotFound";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import ObjectID from "bson-objectid";
import {saveChapterTestID} from "src/slices/chapterSlice";
import {saveSpellingTestID} from "src/slices/wordsSlice";

export const AssessmentHomePage: React.FC = () => {
    const voiceGender = useAppSelector((state: RootState) => state.words.voiceGender);
    const submitWhenEnterKeyIsPressed = useAppSelector((state: RootState) => state.settings.submitWhenEnterKeyIsPressed);

    const title = useAppSelector((state: RootState) => state.pages.assessmentPageTitle);
    const type = useAppSelector((state: RootState) => state.pages.assessmentPageType);
    const chapterID = useAppSelector((state: RootState) => state.chapter.chapterID);
    const spellingID = useAppSelector((state: RootState) => state.words.spellingID);

    const [IDWasInURL, setIDWasInURL] = useState(false)
    const [modRetrievalFailure, setModRetrievalFailure] = useState(false)

    const {id} = useParams();
    const appDispatch = useAppDispatch();
    const {setNavigationPathToHomeIfNeeded} = useSharedMgr();

    // Mod Data was retrieved via URL
    const modRetrieved = (modData: IChapterMod | IMod) => {
        appDispatch(setAssessmentPageTitle(modData.title))
        appDispatch(setAssessmentPageType(modData.type))

        if (modData.type === ModTypes.chapter) {
            popParagraphData(modData as IChapterMod, appDispatch, true);
        } else if (modData.type === ModTypes.spelling) {
            appDispatch(setAssessmentCompletedBeforeStudying(true))
            appDispatch(saveSelectedSpellingInfo({
                spellingID: modData._id.toString(),
                title: modData.title,
                words: "words" in modData ? modData.words : []
            }))
        }
    };

    const retrievalFailure = (error: string) => {
        console.log(`Error: ${error}`)
        setModRetrievalFailure(true)
    };

    useEffect(() => {
        setIDWasInURL(!!id)

        if (id) {
            setModRetrievalFailure(false)
            getMod(id, modRetrieved, retrievalFailure)
        }
    }, []);

    return (
        <>
            {
                (() => {
                    if (IDWasInURL && modRetrievalFailure) return (
                        <ModOrPageNotFound notFoundType={NotFoundType.Mod}/>
                    )

                    return (
                        <Sheet
                            sx={{
                                background: "linear-gradient(to right, #48BB78, #3B82F6)",
                                width: "100vw",
                                height: "100vh",
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <Title title={title}/>
                            <Description description={`Initial Assessment - ${formatDate(new Date())}`}/>
                            <Body boxGap={7}>
                                <Typography
                                    level="h2"
                                    fontSize="x-large"
                                    fontWeight="lg"
                                    sx={{
                                        color: "white",
                                        borderWidth: "2px",
                                        borderColor: "white"
                                    }}
                                    variant="outlined"
                                    padding="15px"
                                    borderRadius="md"
                                >
                                    {
                                        (() => {
                                            if (type === ModTypes.chapter) return (
                                                "Try to answer the questions correctly and quickly."
                                            )

                                            if (type === ModTypes.spelling) return (
                                                "Try to spell the words correctly and quickly."
                                            )
                                        })()
                                    }
                                </Typography>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        boxShadow: 'sm',
                                        borderRadius: 'sm',
                                        p: 3,
                                    }}
                                >
                                    <Typography
                                        level="h4"
                                        paddingBottom="15px"
                                    >
                                        Spelling Voice Gender
                                    </Typography>
                                    <RadioGroup
                                        size="lg"
                                        value={voiceGender} name="radio-buttons-group"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const selectedValue = event.target.value as VoiceGender;
                                            appDispatch(updateVoiceGender(selectedValue));
                                        }}
                                    >
                                        <Radio value="female" label="Female"/>
                                        <Radio value="male" label="Male"/>
                                    </RadioGroup>
                                </Sheet>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        boxShadow: 'sm',
                                        borderRadius: 'sm',
                                        p: 3,
                                    }}
                                >
                                    <Typography
                                        level="h4"
                                        paddingBottom="15px"
                                    >
                                        Submit When 'Enter' Key Is Pressed
                                    </Typography>
                                    <RadioGroup
                                        size="lg"
                                        value={submitWhenEnterKeyIsPressed} name="radio-buttons-group"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            appDispatch(saveSubmitWhenEnterKeyIsPressed(event.target.value));
                                        }}
                                    >
                                        <Radio value="yes" label="Yes"/>
                                        <Radio value="no" label="No"/>
                                    </RadioGroup>
                                </Sheet>
                            </Body>
                            <ButtonRow>
                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                    <Button
                                        sx={resizeButtonSX()}
                                        onClick={
                                            () => {
                                                setNavigationPathToHomeIfNeeded(IDWasInURL);
                                                appDispatch(update(Page.HOME))
                                            }
                                        }
                                    >Cancel</Button>
                                    <Button variant="solid" color="primary"
                                            sx={resizeButtonSX()}
                                            onClick={() => {
                                                if (type === ModTypes.chapter) {
                                                    appDispatch(resetChapterAssessment())
                                                    setNavigationPathToHomeIfNeeded(IDWasInURL)
                                                    appDispatch(update(Page.ANSWER_QUESTION))

                                                    const chapterTestToStart: IModRecord = {
                                                        // @ts-ignore
                                                        _id: ObjectID(newObjectId()),
                                                        // @ts-ignore
                                                        mod_id: ObjectID(chapterID)
                                                    }

                                                    startChapterTest(chapterTestToStart,
                                                        (newChapterTestRecordID) => {
                                                            appDispatch(saveChapterTestID(newChapterTestRecordID))
                                                        },
                                                        (error: any) => console.error("Error:", error)
                                                    )
                                                } else if (type === ModTypes.spelling) {
                                                    setNavigationPathToHomeIfNeeded(IDWasInURL)
                                                    appDispatch(resetSpellingAssessment())
                                                    appDispatch(update(Page.SHOW_WORD))

                                                    const spellingTestToStart: IModRecord = {
                                                        // @ts-ignore
                                                        _id: ObjectID(newObjectId()),
                                                        // @ts-ignore
                                                        mod_id: ObjectID(spellingID)
                                                    }

                                                    startSpellingTest(spellingTestToStart,
                                                        (newSpellingTestRecordID) => {
                                                            appDispatch(saveSpellingTestID(newSpellingTestRecordID))
                                                        },
                                                        (error: any) => console.error("Error:", error)
                                                    )
                                                }
                                            }
                                            }>Go</Button>
                                </ButtonGroup>
                            </ButtonRow>
                        </Sheet>
                    )
                })()
            }
        </>
    )
}