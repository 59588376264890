import {Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog} from "@mui/joy";
import WarningRounded from "@mui/icons-material/WarningRounded";
import React from "react";

export const deleteConfirmationDialog = (desc: string, setOpen: {
    (value: (false | true | { (prevState: boolean): boolean })): void
}, deleteFunction: () => void, setShowDeleteConfirmation: {
    (value: (false | true | { (prevState: boolean): boolean })): void
}) => (
    <Modal open={true} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
            <DialogTitle>
                <WarningRounded/>
                Confirmation
            </DialogTitle>
            <Divider/>
            <DialogContent>
                Are you sure you want to delete this {desc}?
            </DialogContent>
            <DialogActions>
                <Button variant="solid" color="danger"
                        onClick={() => {
                            deleteFunction()
                        }}>
                    Delete {desc}
                </Button>
                <Button variant="plain" color="neutral"
                        onClick={() => setShowDeleteConfirmation(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </ModalDialog>
    </Modal>
);
