import React, {useEffect, useRef, useState} from 'react';
import {Header} from "src/components/Header";
import {Box, Button, Input, Snackbar} from "@mui/joy";
import {Footer} from "src/components/Footer";
import {
    resetCurrentQuestionIndex,
    setCurrentTrafficLight,
    setQuestionHasBeenRead,
    updateAnswer
} from "src/slices/chapterSlice";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {useChapterMgr} from "src/hooks/useChapterMgr";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import Typography from "@mui/joy/Typography";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {resizeButtonSX} from "src/utils/ui";
import Title from "src/components/Shared/Title";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import {
    CurrentAudioComponent,
    resetCorrectAudioOnEnd,
    resetWrongAudioOnEnd,
    setCurrentAudioComponent
} from "src/slices/sharedSlice";
import DarkTrafficLight from "src/images/dark_traffic_light.png"
import {Page, update} from "src/slices/pageSlice";

export const AnswerQuestion: React.FC = () => {
    const [showTypeAnswerMsg, setShowTypeAnswerMsg] = React.useState<boolean>(false);

    const refInput = useRef<HTMLInputElement>(null);

    const currentQuestionIndex = useAppSelector((state: RootState) => state.chapter.currentQuestionIndex);
    const extractedQsandAs = useAppSelector((state: RootState) => state.chapter.extractedQsandAs);
    const userAnswer = useAppSelector((state: RootState) => state.chapter.userAnswer);
    const submitWhenEnterKeyIsPressed = useAppSelector((state: RootState) => state.settings.submitWhenEnterKeyIsPressed);

    const [currentQuestion, setCurrentQuestion] = useState('')

    const {submitAnswer} = useChapterMgr();
    const {playWrongAudio, speak, stopSpeaking} = useSharedMgr();

    const currentAudioComponent = useAppSelector((state: RootState) => state.shared?.currentAudioComponent);
    const correctAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.correctAudioOnEndFired);
    const wrongAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.wrongAudioOnEndFired);
    const currentTrafficLight = useAppSelector((state: RootState) => state.chapter.currentTrafficLight);

    const appDispatch = useAppDispatch();

    useEffect(() => {
        appDispatch(setCurrentAudioComponent(CurrentAudioComponent.ANSWER_QUESTION))
    }, []);

    const checkForTestCompletion = () => {
        if (currentQuestionIndex > extractedQsandAs.length - 1) {
            appDispatch(resetCurrentQuestionIndex())
            appDispatch(update(Page.CHAPTER_RESULT))
        } else {
            setupQuestion();
        }
    }

    useEffect(() => {
        if (correctAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.ANSWER_QUESTION) {
            appDispatch(resetCorrectAudioOnEnd())
            checkForTestCompletion()
        }
    }, [correctAudioOnEndFired])

    useEffect(() => {
        if (wrongAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.ANSWER_QUESTION) {
            appDispatch(resetWrongAudioOnEnd())
            checkForTestCompletion()
        }
    }, [wrongAudioOnEndFired])

    useEffect(() => {
        if (extractedQsandAs.length > 0 && currentQuestionIndex < extractedQsandAs.length) {
            setupQuestion();
        }
    }, [extractedQsandAs]);

    const setupQuestion = () => {
        let currentQuestion = extractedQsandAs![currentQuestionIndex]?.question

        if (!currentQuestion)
            return

        refInput.current!.disabled = false
        refInput.current?.focus();
        speak(currentQuestion)
        setCurrentQuestion(currentQuestion)
        appDispatch(updateAnswer(''));
        appDispatch(setQuestionHasBeenRead(true));
        appDispatch(setCurrentTrafficLight(DarkTrafficLight))
    }

    const doSubmission = () => {
        refInput.current!.disabled = true
        submitAnswer();
        stopSpeaking();
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <Header/>
            <Title title={'Answer the Question'}/>
            <Body boxGap={7}>
                <Typography fontSize="x-large">
                    {currentQuestion}
                </Typography>
                <Input
                    slotProps={{
                        input: {
                            ref: refInput
                        },
                    }}
                    value={userAnswer}
                    placeholder="Type Answer Here"
                    size="lg"
                    onKeyDown={
                        (event) => {
                            if (event.key === 'Enter' && userAnswer.trim() !== '' && submitWhenEnterKeyIsPressed === 'yes') {
                                doSubmission()
                            }
                        }
                    }
                    onChange={(event) => appDispatch(updateAnswer(event.target.value))}
                    spellCheck="false"
                />
                <Box
                    component="img"
                    sx={{
                        height: 125
                    }}
                    alt={
                        'Traffic Light'
                    }
                    src={
                        currentTrafficLight
                    }
                />
            </Body>
            <ButtonRow>
                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                    <Button sx={resizeButtonSX()} onClick={
                        () => {
                            speak(currentQuestion);
                            refInput.current?.focus();
                        }
                    }
                    >
                        Read Sentence
                    </Button>
                    <Button sx={resizeButtonSX()} variant="solid" color="primary" onClick={
                        () => {
                            if (userAnswer.trim() === '') {
                                refInput.current!.disabled = true
                                setShowTypeAnswerMsg(true)
                                playWrongAudio()
                                return
                            }

                            doSubmission()
                        }
                    }>
                        Submit
                    </Button>
                </ButtonGroup>
            </ButtonRow>
            <Footer/>
            <Snackbar
                size="lg"
                variant="solid"
                color="warning"
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={1750}
                open={showTypeAnswerMsg}
                onClose={() => {
                    setShowTypeAnswerMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                }}
            >
                Please answer the question and then Submit.
            </Snackbar>
        </div>
    );
}
