import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import Typography from "@mui/joy/Typography";
import {Box, Checkbox, ColorPaletteProp, FormLabel, Input, Snackbar} from "@mui/joy";
import {setInputColor} from "src/utils/functions";
import {TextInputColor} from "src/utils/constants";
import {z} from "zod";
import {ImportInfo} from "src/utils/interfaces";
import {ModTypes} from "src/utils/constants";
import Link from "@mui/joy/Link";

export const OrgModSchema = z.object(
    {
        org: z.string().trim().min(1),
        title: z.string().trim().min(1),
        subject: z.string().trim().min(1),
        grade: z.string().trim().min(1),
        academicYear: z.string().trim().min(1),
        desc: z.string(),
        available: z.boolean()
    }
)

interface ImportOrgModHeaderProps {
    type: string
}

interface CombinedProps extends ImportInfo, ImportOrgModHeaderProps {
}

export const ImportOrgModHeader: React.FC<CombinedProps> = ({
                                                                type,
                                                                saveAllInfoClicked,
                                                                saveInfo,
                                                                existingChapter
                                                            }) => {
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [orgModeInfo, setOrgModeInfo] = useState<z.infer<typeof OrgModSchema>>({
        org: '',
        academicYear: '24-25',
        title: '',
        subject: '',
        grade: '',
        desc: '',
        available: false
    })

    const [academicYearInputColor, setAcademicYearInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [orgInputColor, setOrgInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [subjectInputColor, setSubjectInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [titleInputColor, setTitleInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [gradeInputColor, setGradeInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const refAcademicYear = useRef<HTMLInputElement | null>(null);
    const refOrg = useRef<HTMLInputElement | null>(null);
    const refGrade = useRef<HTMLInputElement | null>(null);
    const refTitle = useRef<HTMLInputElement | null>(null);
    const refSubject = useRef<HTMLInputElement | null>(null);

    const highlightInvalidField = (fieldName: string[] | undefined, inputElem: HTMLInputElement, setInputColorAction: Dispatch<SetStateAction<TextInputColor>>) => {
        if (fieldName !== undefined) {
            inputElem.focus()
            setInputColorAction(TextInputColor.DANGER)
            setSnackbarMsg(fieldName[0])
            setShowSnackbarMsg(true)
        }
    }

    useEffect(() => {
        refAcademicYear.current?.focus()

        if (type === ModTypes.chapter && existingChapter) {
            setOrgModeInfo({
                academicYear: existingChapter.academic_year,
                available: existingChapter.available,
                desc: existingChapter.desc!,
                grade: existingChapter.grade!.toString(),
                org: existingChapter.org,
                subject: existingChapter.subject,
                title: existingChapter.title
            })
        }
    }, []);

    useEffect(() => {
        if (saveAllInfoClicked) {
            // Note that we 'omit' grade when validating, which is initially typed as a z.string() rather than z.number(), which would require a default value for a non-optional key. This default value (assume it's 0) would hide the 'Grade' placeholder from being visible in the Input box.
            // Zod allows us to use the number validation we want on Grade by using 'extend' and 'coerce' on submission.
            const result = OrgModSchema.omit({grade: true}).extend({grade: z.coerce.number().min(4).max(12)}).safeParse(orgModeInfo)

            if (!result.success) {
                highlightInvalidField(result.error.flatten().fieldErrors.title, refTitle.current!, setTitleInputColor)
                highlightInvalidField(result.error.flatten().fieldErrors.subject, refSubject.current!, setSubjectInputColor)
                highlightInvalidField(result.error.flatten().fieldErrors.grade, refGrade.current!, setGradeInputColor)
                highlightInvalidField(result.error.flatten().fieldErrors.org, refOrg.current!, setOrgInputColor)
                highlightInvalidField(result.error.flatten().fieldErrors.academicYear, refAcademicYear.current!, setAcademicYearInputColor)
                return
            }

            saveInfo(orgModeInfo)
        }
    }, [saveAllInfoClicked])

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography level="h1" fontWeight="lg" fontSize="x-large"
                            sx={{paddingBottom: "5px"}}>
                    <u>Import {type}</u>
                </Typography>
                <Box
                    sx={{
                        display: 'grid',
                        gap: .5,
                        whiteSpace: 'nowrap',
                    }}
                >
                    <FormLabel>Academic Year *</FormLabel>
                    <Input color={academicYearInputColor as ColorPaletteProp}
                           value={orgModeInfo.academicYear} slotProps={{
                        input: {
                            ref: refAcademicYear
                        },
                    }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setAcademicYearInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       academicYear: event.target.value
                                   })
                               )
                           }
                           }
                    />
                    <FormLabel>Organization *</FormLabel>
                    <Input color={orgInputColor as ColorPaletteProp}
                           value={orgModeInfo.org}
                           slotProps={{
                               input: {
                                   ref: refOrg
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setOrgInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       org: event.target.value
                                   })
                               )
                           }}
                    />
                    <FormLabel>Grade *</FormLabel>
                    <Input color={gradeInputColor as ColorPaletteProp}
                           value={orgModeInfo.grade}
                           slotProps={{
                               input: {
                                   type: 'number',
                                   ref: refGrade
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setGradeInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       grade: event.target.value
                                   })
                               )
                           }}
                    />
                    <FormLabel>Subject *</FormLabel>
                    <Input color={subjectInputColor as ColorPaletteProp}
                           value={orgModeInfo.subject}
                           slotProps={{
                               input: {
                                   ref: refSubject
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setSubjectInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       subject: event.target.value
                                   })
                               )
                           }}
                    />
                    <FormLabel>Mod Title *</FormLabel>
                    <Input color={titleInputColor as ColorPaletteProp}
                           value={orgModeInfo.title}
                           slotProps={{
                               input: {
                                   ref: refTitle
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setTitleInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       title: event.target.value
                                   })
                               )
                           }
                           }
                    />
                    <FormLabel>Description</FormLabel>
                    <Input value={orgModeInfo.desc}
                           onChange={(event) =>
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       desc: event.target.value
                                   })
                               )
                           }
                    />
                    <div style={{display: 'flex', whiteSpace: 'nowrap', alignItems: 'center'}}>
                        <FormLabel>Mod Available to Study?</FormLabel>
                        &nbsp;&nbsp;
                        <Checkbox checked={orgModeInfo.available} onClick={() =>
                            setOrgModeInfo(prevState => ({
                                    ...prevState,
                                    available: !prevState.available
                                })
                            )
                        }
                        />
                    </div>
                    <Link
                        underline='always'
                        onClick={async () => {
                            await navigator.clipboard.writeText('Summarize the essential points in the following paragraph by removing the extraneous text and making the paragraph more concise. The output should be in paragraph format. \n\n')
                        }}>
                        Copy 'Summarize Paragraph'
                    </Link>
                    <Link
                        underline='always'
                        onClick={async () => {
                            await navigator.clipboard.writeText('Create multiple choice questions and answers from the following paragraph.  The first answer shown should be the correct one. Do not prefix the answers with "A)", "B)", etc.).  For each created question, show 4 answers. \n\n')
                        }}>
                        Copy 'Create MCQ'
                    </Link>
                    <div>
                        <Link
                            underline='always'
                            onClick={async () => {
                                await navigator.clipboard.writeText('Create questions and answers from the following paragraph.  Only show the question and answer. The answer should be 4 words or less and the exact text of the answer should be found in the paragraph. \n\n')
                            }}
                        >
                            Copy 'Create Q & A'
                        </Link>
                    </div>
                </Box>
            </Box>
            <Snackbar
                size="lg"
                variant="solid"
                color={"danger"}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={3000}
                open={showSnackbarMsg}
                onClose={() => {
                    setShowSnackbarMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                    whiteSpace: 'pre-line'
                }}
            >
                {snackbarMsg}
            </Snackbar>
        </>
    )
}