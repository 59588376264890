import {IModType} from "@backend/enums";

export enum localStorageKeys {
    VoiceGender = 'voiceGender',
    SubmitWhenEnterKeyIsPressed = 'submitWhenEnterKeyIsPressed'
}

export enum Paths {
    Home = '/',
    ImportWords = '/import-words',
    ImportChapter = '/import-chapter',
    AllChapters = '/all-chapters',
    UnavailableChapters = '/unavailable-chapters',
}

export enum TextInputColor {
    NEUTRAL = 'neutral',
    DANGER = 'danger'
}

export const ModTypes: { [K in keyof Required<IModType>]: K } = {
    chapter: 'chapter',
    spelling: 'spelling',
    none: 'none'
};