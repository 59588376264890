import React, {useEffect, useState} from 'react';
import {Header} from "src/components/Header";
import {ImportOrgModHeader, OrgModSchema} from "src/components/ImportOrgModHeader";
import {z} from "zod";
import MultipleChoice, {ParagraphMultipleChoiceSchema} from "src/components/Chapter/MultipleChoice";
import {Box, Button, ColorPaletteProp, Divider, List, ListItem, Snackbar} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import QuestionsAndAnswers, {ParagraphQuestionsAndAnswersSchema} from "src/components/Chapter/QuestionsAndAnswers";
import ParagraphText, {ParagraphTextSchema} from "src/components/Chapter/ParagraphText";
import {
    IChapterMod,
    IChapterModParagraph,
    IChapterModParagraphMultiple_choice,
    IChapterModParagraphQuestions_and_answer
} from "@backend/mongoose.gen";
import {newObjectId} from "src/utils/functions";
import {saveChapterMod, updateChapterMod} from "src/utils/api-service";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {
    addChapter,
    addParagraph,
    clearParagraphInfoClicked, Paragraph,
    setCurrentParagraphID, setNewParagraphText,
    updateParagraph
} from "src/slices/chapterSlice";
import {ModTypes, Paths} from "src/utils/constants";
import ObjectID from "bson-objectid";
import {BulkInsertContainer} from "src/components/Shared/BulkInsertContainer";
import {useNavigate} from "react-router-dom";

export const ImportChapter: React.FC = () => {
    const [importComplete, setImportComplete] = useState(false)
    const [saveAllInfoClicked, setSaveAllInfoClicked] = useState(false)
    const [paragraphSaved, setParagraphSaved] = useState(false)
    const [saveParagraphClicked, setSaveParagraphClicked] = useState(false)
    const [orgModeInfo, setOrgModeInfo] = useState<z.infer<typeof OrgModSchema> | null>(null)
    const [paragraphText, setParagraphText] = useState<z.infer<typeof ParagraphTextSchema>>()
    const [multipleChoiceArray, setMultipleChoiceArray] = useState<z.infer<typeof ParagraphMultipleChoiceSchema>[]>([])
    const [questionsAndAnswersArray, setQuestionsAndAnswersArray] = useState<z.infer<typeof ParagraphQuestionsAndAnswersSchema>[]>([])
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, setSnackbarMsgColor] = useState("neutral")

    const existingChapterData = useAppSelector((state: RootState) => state.chapter.existingChapterData);
    const paragraphs = useAppSelector((state: RootState) => state.chapter.paragraphs);
    const currentParagraphID = useAppSelector((state: RootState) => state.chapter.currentParagraphID);
    const clearParagraphInfo = useAppSelector((state: RootState) => state.chapter.clearParagraphInfo);

    const appDispatch = useAppDispatch();

    const navigate = useNavigate();

    const atLeastOneQ_and_A_AnswerMustBeInTheParagraph: string = 'At least one Q and A Answer must be in the Paragraph.'

    const mapParagraphsToModel = () => {
        return paragraphs.map((paragraph, index) => {
            const modParagraph: IChapterModParagraph = {
                _id: index + 1,
                text: paragraph.textObj.text,
                questions_and_answers: paragraph.questionsAndAnswersArray.map((questionAndAnswer, index) => {
                    const modQuestionAndAnswer: IChapterModParagraphQuestions_and_answer = {
                        _id: index + 1,
                        question: questionAndAnswer.question,
                        answer: questionAndAnswer.answer,
                        is_topic: questionAndAnswer.is_topic
                    }
                    return modQuestionAndAnswer;
                }),
                multiple_choices: paragraph.multipleChoiceArray.map((multipleChoice, index) => {
                    const modMultipleChoice: IChapterModParagraphMultiple_choice = {
                        _id: index + 1,
                        question: multipleChoice.question,
                        answer: multipleChoice.answer,
                        options: multipleChoice.options
                    }

                    return modMultipleChoice;
                })
            }

            return modParagraph
        })
    }

    const chapterImportedOrUpdated = (result: any) => {
        if (existingChapterData) {
            setSnackbarMsg('The Chapter has been updated in the DB.')
        } else {
            const chapter: IChapterMod = {
                academic_year: orgModeInfo!.academicYear,
                available: orgModeInfo!.available,
                grade: parseInt(orgModeInfo!.grade),
                org: orgModeInfo!.org,
                paragraphs: mapParagraphsToModel(),
                serial_no: 4,
                subject: orgModeInfo!.subject,
                title: orgModeInfo!.title,
                type: ModTypes.chapter,
                _id: result.data.id
            }

            appDispatch(addChapter(chapter))
            setSnackbarMsg('The Chapter has been imported to the DB.')
        }

        setSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
        setImportComplete(true)
    };

    const importFailure = (error: string) => {
        setSnackbarMsg(`Error occurred importing Chapter ... ${error}`)
        setSnackbarMsgColor('danger')
        setShowSnackbarMsg(true)
    };

    useEffect(() => {
        if (prompt("Do the needful:") !== process.env.REACT_APP_KEY) {
            navigate(Paths.Home)
        }
    }, []);

    useEffect(() => {
            if (paragraphText && paragraphText.text.trim() !== '') {
                let errorText = ''

                if (errorText === '' && multipleChoiceArray.length === 0) {
                    errorText = 'Multiple Choice'
                }

                if (errorText === '' && questionsAndAnswersArray.length === 0) {
                    errorText = 'Question and Answer'
                }

                if (errorText) {
                    setSaveParagraphClicked(false)
                    setSnackbarMsg(errorText === atLeastOneQ_and_A_AnswerMustBeInTheParagraph ? atLeastOneQ_and_A_AnswerMustBeInTheParagraph : `Please add at least one ${errorText} entry.`)
                    setShowSnackbarMsg(true)
                    setSnackbarMsgColor('danger')
                    return
                }

                if (currentParagraphID) {
                    setSnackbarMsg('Paragraph has been updated.')
                    appDispatch(updateParagraph({
                        _id: currentParagraphID,
                        textObj: paragraphText,
                        multipleChoiceArray: multipleChoiceArray,
                        questionsAndAnswersArray: questionsAndAnswersArray
                    }))
                } else {
                    setSnackbarMsg('Paragraph has been added.')
                    appDispatch(addParagraph({
                        _id: String(paragraphs.length + 1),
                        textObj: paragraphText,
                        multipleChoiceArray: multipleChoiceArray,
                        questionsAndAnswersArray: questionsAndAnswersArray
                    }));
                }

                setParagraphSaved(true)
                setParagraphText({text: ''})
                setMultipleChoiceArray([])
                setQuestionsAndAnswersArray([])
                setSnackbarMsgColor('success')
                setShowSnackbarMsg(true)
            }
        }, [saveParagraphClicked, paragraphText, multipleChoiceArray, questionsAndAnswersArray]
    );

    useEffect(() => {
        if (clearParagraphInfo) {
            appDispatch(setCurrentParagraphID(''))
            setParagraphText({text: ''})
            setMultipleChoiceArray([])
            setQuestionsAndAnswersArray([])
        }
    }, [clearParagraphInfo]);

    const validateIsTopicIsTrueForAtLeastOneQandA_Answer = (chapterData: Paragraph[]) => chapterData.some(obj => obj.questionsAndAnswersArray?.some(question => question.is_topic))

    useEffect(() => {
        if (orgModeInfo) {
            if (paragraphs.length === 0) {
                setSnackbarMsg('Please add at least one paragraph.')
                setSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }

            if (!validateIsTopicIsTrueForAtLeastOneQandA_Answer(paragraphs)) {
                setSnackbarMsg('There must be at least one Q and A answer in the Paragraphs with \'is_topic\' checked.')
                setSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }

            let infoToSave: IChapterMod = {
                available: orgModeInfo.available,
                // @ts-ignore
                _id: existingChapterData ? ObjectID(existingChapterData._id) : ObjectID(newObjectId()),
                academic_year: orgModeInfo.academicYear.trim(),
                desc: orgModeInfo.desc.trim(),
                grade: parseInt(orgModeInfo.grade),
                org: orgModeInfo.org.trim(),
                serial_no: 4,
                subject: orgModeInfo.subject.trim(),
                title: orgModeInfo.title.trim(),
                type: ModTypes.chapter,
                paragraphs: mapParagraphsToModel()
            }

            if (existingChapterData) {
                updateChapterMod(infoToSave, chapterImportedOrUpdated, importFailure)
            } else {
                saveChapterMod(infoToSave, chapterImportedOrUpdated, importFailure)
            }

            setImportComplete(true)
        }

        setSaveAllInfoClicked(false)
    }, [saveAllInfoClicked])

    const saveAllInfoToDB = () => {
        setOrgModeInfo(null)
        setImportComplete(false)
        setSaveAllInfoClicked(true)
    };

    const selectParagraph = (id: string) => {
        appDispatch(clearParagraphInfoClicked(false))
        appDispatch(setCurrentParagraphID(id))
    };

    const saveOrUpdateParagraphInfo = () => {
        setParagraphSaved(false)
        setSaveParagraphClicked(true)
    }

    return (
        <>
            <Header/>
            <Box
                sx={{
                    marginLeft: '20px',
                    gap: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: 'fit-content',
                }}
            >
                <ImportOrgModHeader
                    type={ModTypes.chapter}
                    importComplete={importComplete}
                    saveAllInfoClicked={saveAllInfoClicked}
                    saveInfo={(orgModeInfo: z.infer<typeof OrgModSchema>) => setOrgModeInfo(orgModeInfo)}
                    existingChapter={existingChapterData}
                />
                <Divider orientation="vertical" sx={{bgcolor: "darkblue", marginTop: '15px'}}/>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '10px',
                        overflow: 'scroll',
                        maxHeight: '49vh'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            level="inherit"
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Number of Paragraphs Added: {paragraphs.length}
                        </Typography>
                    </Box>
                    <List
                        marker={'decimal'}
                        sx={{width: '97%'}}
                    >
                        {paragraphs.map((paragraph, index) => (
                            <ListItem
                                style={{cursor: 'pointer'}}
                                key={index}
                                onClick={() => {
                                    selectParagraph(paragraph._id)
                                }}>{paragraph.textObj.text}</ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
            <ParagraphText
                paragraphSaved={paragraphSaved}
                saveParagraphTextInfoClicked={saveParagraphClicked}
                saveParagraphInfo={(paragraphTextInfo: z.infer<typeof ParagraphTextSchema>) => {
                    setParagraphText(paragraphTextInfo)

                    if (!currentParagraphID) {
                        appDispatch(setNewParagraphText(paragraphTextInfo.text))
                    }
                }}
                setSaveParagraphTextInfoClickedToFalse={() => setSaveParagraphClicked(false)}
            />
            <BulkInsertContainer/>
            <MultipleChoice
                paragraphSaved={paragraphSaved}
                saveParagraphTextInfoClicked={saveParagraphClicked}
                saveParagraphInfo={(multipleChoiceInfo: z.infer<typeof ParagraphMultipleChoiceSchema>[]) => setMultipleChoiceArray(multipleChoiceInfo)}
            />
            <QuestionsAndAnswers
                paragraphSaved={paragraphSaved}
                saveParagraphTextInfoClicked={saveParagraphClicked}
                saveParagraphInfo={(questionsAndAnswersInfo: z.infer<typeof ParagraphQuestionsAndAnswersSchema>[]) => setQuestionsAndAnswersArray(questionsAndAnswersInfo)}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: 10
                }}
            >
                <Button
                    color='success'
                    size="lg"
                    sx={{
                        marginTop: "10px"
                    }}
                    onClick={saveAllInfoToDB}
                >
                    <Typography
                        fontSize="large">{existingChapterData ? 'Update Exising Chapter Info in DB' : 'Save All Info to DB'}
                    </Typography>
                </Button>
                <Button
                    size="lg"
                    sx={{
                        marginTop: "10px"
                    }}
                    onClick={
                        saveOrUpdateParagraphInfo
                    }
                >
                    <Typography
                        fontSize="large">{`${currentParagraphID === '' ? 'Save' : 'Update'} Paragraph Info`}
                    </Typography>
                </Button>
                <Button
                    size="lg"
                    sx={{
                        marginTop: "10px"
                    }}
                    onClick={
                        () => {
                            appDispatch(clearParagraphInfoClicked(true))
                        }
                    }
                >
                    <Typography
                        fontSize="large">Clear Paragraph Info</Typography>
                </Button>
            </Box>
            <Snackbar
                size="lg"
                variant="solid"
                color={snackbarMsgColor as ColorPaletteProp}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={3000}
                open={showSnackbarMsg}
                onClose={() => {
                    setShowSnackbarMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                    whiteSpace: 'pre-line'
                }}
            >
                {snackbarMsg}
            </Snackbar>
        </>
    )
}

export default ImportChapter