import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {localStorageKeys} from "src/utils/constants";

export interface SettingsState {
    submitWhenEnterKeyIsPressed: string
}

const initialState: SettingsState = {
    submitWhenEnterKeyIsPressed: ((): string => {
        if (localStorage.getItem(localStorageKeys.SubmitWhenEnterKeyIsPressed)) {
            return <string>localStorage.getItem(localStorageKeys.SubmitWhenEnterKeyIsPressed)
        }

        return 'no'
    })()
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        saveSubmitWhenEnterKeyIsPressed(state, action: PayloadAction<string>) {
            state.submitWhenEnterKeyIsPressed = action.payload;
            localStorage.setItem(localStorageKeys.SubmitWhenEnterKeyIsPressed, action.payload.toString());
        }
    }
})

export const {
    saveSubmitWhenEnterKeyIsPressed
} = settingsSlice.actions;

export default settingsSlice.reducer;