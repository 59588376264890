import React, {FC, useEffect, useState} from "react"
import {Box, Button, Textarea} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {RichTreeView, TreeViewBaseItem, useTreeViewApiRef} from "@mui/x-tree-view";
import {saveBulkInsertMultipleChoiceArray} from "src/slices/chapterSlice"
import {saveBulkInsertQuestionAndAnswerArray} from "src/slices/chapterSlice"
import {useAppDispatch} from "src/app/hooks";

export enum BulkInsertType {
    MCQ = 'mcq',
    QA = 'qa'
}

interface Props {
    typeOfInsert: BulkInsertType
}

const BulkInsert: FC<Props> = ({typeOfInsert}) => {
    const [bulkInsertData, setBulkInsertData] = useState<TreeViewItem[]>([])
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [expandedItems, setExpandedItems] = useState<string[]>([]);
    const [questionIDs, setQuestionIDs] = useState<string[]>([]);
    const [bulkInsertText, setBulkInsertText] = useState('')

    const toggledItemRef = React.useRef<{ [itemId: string]: boolean }>({});
    const apiRef = useTreeViewApiRef();
    const appDispatch = useAppDispatch();

    const insertItems = () => {
// doValidation
        if (typeOfInsert === BulkInsertType.MCQ) {
            appDispatch(saveBulkInsertMultipleChoiceArray(bulkInsertData.filter(item => selectedItems.includes(item.id)).map(question => ({
                question: question.label,
                answer: question.children![0].label,
                options: question.children!.slice(1, 4).map(option => option.label)
            }))))
        } else if (typeOfInsert === BulkInsertType.QA) {
            appDispatch(saveBulkInsertQuestionAndAnswerArray(bulkInsertData.filter(item => selectedItems.includes(item.id)).map(question => ({
                question: question.label,
                answer: question.children![0].label,
                is_topic: false
            }))))
        }

        setBulkInsertData([])
        setBulkInsertText('')
    };

    useEffect(() => {
        if (questionIDs.length > 0) {
            setExpandedItems(questionIDs.slice())
        }
    }, [questionIDs]);

    const handleItemSelectionToggle = (
        event: React.SyntheticEvent,
        itemId: string,
        isSelected: boolean,
    ) => {
        toggledItemRef.current[itemId] = isSelected;
    };

    const handleSelectedItemsChange = (
        event: React.SyntheticEvent,
        newSelectedItems: string[],
    ) => {
        setSelectedItems(newSelectedItems);

        // Select / unselect the children of the toggled item
        const itemsToSelect: string[] = [];
        const itemsToUnSelect: { [itemId: string]: boolean } = {};
        Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
            // @ts-ignore
            const item = apiRef.current!.getItem(itemId);
            if (isSelected) {
                itemsToSelect.push(...getItemDescendantsIds(item));
            } else {
                getItemDescendantsIds(item).forEach((descendantId) => {
                    itemsToUnSelect[descendantId] = true;
                });
            }
        });

        const newSelectedItemsWithChildren = Array.from(
            new Set(
                [...newSelectedItems, ...itemsToSelect].filter(
                    (itemId) => !itemsToUnSelect[itemId],
                ),
            ),
        );

        setSelectedItems(newSelectedItemsWithChildren);

        toggledItemRef.current = {};
    };

    function getItemDescendantsIds(item: TreeViewBaseItem) {
        const ids: string[] = [];
        item.children?.forEach((child) => {
            ids.push(child.id);
            ids.push(...getItemDescendantsIds(child));
        });

        return ids;
    }

    const parseQandAs = (input: string): TreeViewItem[] => {
        const lines = input.split('\n').map(line => line.trim()).filter(line => line !== ''); // Remove empty lines
        const result: TreeViewItem[] = [];
        let idCounter = 1;
        let numberOfLinesAfterQuestion = BulkInsertType.MCQ === typeOfInsert ? 4 : 1

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];

            // Remove any initial number followed by a space
            if (/^\d+\.\s/.test(line)) {
                line = line.replace(/^\d+\.\s*/, '');
            }

            // Every question is followed by 4 answers for MCQ. For QA, there is only the question and the answer (1 line).
            if (i + numberOfLinesAfterQuestion < lines.length) {
                const question: TreeViewItem = {
                    id: idCounter.toString(),
                    label: line,
                    checked: true,
                    isExpanded: true,
                    children: []
                };

                for (let j = 1; j <= numberOfLinesAfterQuestion; j++) {
                    let answerLine = lines[i + j].trim();

                    if (answerLine.endsWith('.')) {
                        answerLine = answerLine.slice(0, -1); // trim the period
                    }

                    // Remove initial dashes and spaces for answers
                    if (/^-/.test(answerLine)) {
                        answerLine = answerLine.replace(/^-+\s*/, '');
                    }

                    const answer: TreeViewItem = {
                        id: `${idCounter}.${j}`,
                        label: answerLine,
                        disabled: true
                    };

                    question.children!.push(answer);
                }

                result.push(question);
                setQuestionIDs(prevState => [...prevState, question.id])
                idCounter++;
                i += numberOfLinesAfterQuestion; // Skip the 4 answer lines for MCQ. For QA, skip the 1 answer line.
            }
        }

        setSelectedItems(questionIDs)

        return result;
    };

    interface TreeViewItem extends TreeViewBaseItem {
        id: string;
        label: string;
        disabled?: boolean;
        isExpanded?: boolean;
        checked?: boolean
    }

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedItems(itemIds);
    };

    const isItemDisabled = (item: TreeViewItem) => !!item.disabled;

    return (
        <Box
            sx={{
                flex: 1,
                p: 3,
                boxShadow: 'lg',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Textarea
                maxRows={1}
                value={bulkInsertText}
                placeholder={typeOfInsert === BulkInsertType.MCQ ? 'Paste MCQs Here' : 'Paste QAs Here'}
                style={{width: "100%"}}
                onChange={(event) => {
                    setBulkInsertData([])
                    setQuestionIDs([])
                    setExpandedItems([])
                    setBulkInsertData(parseQandAs(event.target.value))
                    setBulkInsertText(event.target.value)
                }
                }
            />
            <Box>
                <RichTreeView
                    multiSelect
                    checkboxSelection
                    apiRef={apiRef}
                    items={bulkInsertData}
                    expandedItems={expandedItems}
                    isItemDisabled={isItemDisabled}
                    selectedItems={selectedItems}
                    onSelectedItemsChange={handleSelectedItemsChange}
                    onItemSelectionToggle={handleItemSelectionToggle}
                    onExpandedItemsChange={handleExpandedItemsChange}
                />
            </Box>
            <Button size="lg"
                    sx={{
                        marginTop: "10px"
                    }}
                    onClick={
                        () => insertItems()
                    }
            >
                <Typography fontSize="large">Insert</Typography>
            </Button>
        </Box>
    );
};

export default BulkInsert;