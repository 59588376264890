import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TitleProps {
    title: string;
}

const Title: React.FC<TitleProps> = ({title}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '10vh',
            }}
        >
            <Typography variant="h1" fontWeight="bold" fontSize="xxx-large">
                {title}
            </Typography>
        </Box>
    );
};

export default Title;