import React, {Dispatch, SetStateAction} from "react";
import {TextInputColor} from "src/utils/constants";
import {AnyAction, ThunkDispatch} from "@reduxjs/toolkit";
import {Paragraph, saveSelectedChapterInfo} from "src/slices/chapterSlice";
import {IChapterMod} from "@backend/mongoose.gen";
import _ from "lodash";

export const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {day: '2-digit', month: 'short', year: 'numeric'};
    return new Date(date).toLocaleDateString('en-US', options).replace(/(\w+) (\d+), (\d+)/, "$2-$1-$3");
};

export const newObjectId = (): string => {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
        return Math.floor(Math.random() * 16).toString(16);
    }).toLowerCase();
}


export const setInputColor = (value: string, setInputColor: React.Dispatch<React.SetStateAction<TextInputColor>>) => {
    if (value.trim() === '') {
        setInputColor(TextInputColor.DANGER)
    } else {
        setInputColor(TextInputColor.NEUTRAL)
    }
}

export const highlightInvalidField = (fieldName: string[] | undefined, inputElem: HTMLInputElement[] | HTMLTextAreaElement[], setInputColorAction: Dispatch<SetStateAction<TextInputColor>>[], setSnackbarMsg: Dispatch<SetStateAction<string>>, setShowSnackbarMsg: Dispatch<SetStateAction<boolean>>, showSnackbarMsgColor: Dispatch<SetStateAction<string>>) => {
    if (fieldName !== undefined) {
        setSnackbarMsg(fieldName[0])

        if (inputElem.length === 1) {
            inputElem[0].focus()
            setInputColorAction[0](TextInputColor.DANGER)
        }

        showSnackbarMsgColor(TextInputColor.DANGER)
        setShowSnackbarMsg(true)
    }
}

export const getDuration = (refStartDuration: React.MutableRefObject<number>): number => {
    const duration = new Date().getTime() - refStartDuration.current;

    refStartDuration.current = new Date().getTime();

    return duration;
};

export const popParagraphData = (modData: IChapterMod, appDispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction>, assessmentCompletedBeforeStudying: boolean
) => {
    const paragraphs: Paragraph[] = [];

    modData.paragraphs.map(paragraph => {
        paragraphs.push({
            _id: _.uniqueId(),
            textObj: {text: paragraph.text},
            multipleChoiceArray: paragraph.multiple_choices,
            questionsAndAnswersArray: paragraph.questions_and_answers
        })
    })

    appDispatch(saveSelectedChapterInfo({
        chapterID: modData._id.toString(),
        title: modData.title,
        paragraphs: paragraphs,
        assessmentCompletedBeforeStudying: assessmentCompletedBeforeStudying

    }))
};

export const getAnswerTextToEnter = (value: string) => {
    const prefixes = ["correct answer:", "answer:", "a:"];
    const prefix = prefixes.find(prefix => value?.toLowerCase().startsWith(prefix));

    if (prefix) {
        value = value.endsWith('.') ? value.slice(0, -1) : value
        return value.slice(value.toLowerCase().indexOf(prefix) + prefix.length).trim();
    }

    return value;
};