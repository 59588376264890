import React, {useEffect, useRef, useState} from 'react'
import {
    Box,
    Button,
    Checkbox,
    ColorPaletteProp,
    IconButton,
    Input,
    List,
    ListItem,
    Sheet,
    Snackbar,
    Table, Textarea
} from "@mui/joy";
import {KeyboardArrowDown} from "@mui/icons-material";
import Typography from "@mui/joy/Typography";
import {getAnswerTextToEnter, highlightInvalidField, setInputColor} from "src/utils/functions";
import {z} from "zod";
import {TextInputColor} from "src/utils/constants";
import {SaveParagraphInfo} from "src/components/Chapter/ParagraphText";
import {useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {deleteConfirmationDialog} from "src/components/Shared/DeleteModal";

export const ParagraphQuestionsAndAnswersSchema = z.object(
    {
        question: z.string().trim().min(1),
        answer: z.string().trim().min(1),
        is_topic: z.boolean()
    }
)

export const QuestionsAndAnswers: React.FC<SaveParagraphInfo> = ({
                                                                     paragraphSaved,
                                                                     saveParagraphTextInfoClicked,
                                                                     saveParagraphInfo,
                                                                 }) => {
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, showSnackbarMsgColor] = useState("neutral")
    const [questionsAndAnswersInfoArray, setQuestionsAndAnswersInfoArray] = useState<z.infer<typeof ParagraphQuestionsAndAnswersSchema>[]>([])
    const [questionsAndAnswersInfo, setQuestionsAndAnswersInfo] = useState<z.infer<typeof ParagraphQuestionsAndAnswersSchema>>(
        {
            question: '',
            answer: '',
            is_topic: false
        }
    )
    const [questionInputColor, setQuestionInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [answerInputColor, setAnswerInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [editClicked, setEditClicked] = useState(false)

    const currentParagraphID = useAppSelector((state: RootState) => state.chapter.currentParagraphID);
    const newParagraphText = useAppSelector((state: RootState) => state.chapter.newParagraphText);
    const paragraphs = useAppSelector((state: RootState) => state.chapter.paragraphs);
    const clearParagraphInfo = useAppSelector((state: RootState) => state.chapter.clearParagraphInfo);
    const bulkInsertQuestionAndAnswerArray = useAppSelector((state: RootState) => state.chapter.bulkInsertQuestionAndAnswerArray);

    const refQuestion = useRef<HTMLTextAreaElement | null>(null);
    const refAnswer = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (saveParagraphTextInfoClicked) {
            if (questionsAndAnswersInfoArray.length === 0) {
                return
            }

            saveParagraphInfo(questionsAndAnswersInfoArray)
        }
    }, [saveParagraphTextInfoClicked])

    useEffect(() => {
        if (currentParagraphID !== '') {
            let tempQuestionsAndAnswersInfoArray = paragraphs.find(p => p._id === currentParagraphID)?.questionsAndAnswersArray

            setQuestionsAndAnswersInfo({
                question: '',
                answer: '',
                is_topic: false
            })
            setQuestionsAndAnswersInfoArray(tempQuestionsAndAnswersInfoArray as z.infer<typeof ParagraphQuestionsAndAnswersSchema>[])
        }
    }, [currentParagraphID]);

    useEffect(() => {
        if (paragraphSaved) {
            setQuestionsAndAnswersInfoArray([])
        }
    }, [paragraphSaved]);

    useEffect(() => {
        if (clearParagraphInfo) {
            setQuestionsAndAnswersInfoArray([])
        }
    }, [clearParagraphInfo]);

    useEffect(() => {
        if (bulkInsertQuestionAndAnswerArray?.length > 0) {
            setQuestionsAndAnswersInfoArray([...questionsAndAnswersInfoArray, ...bulkInsertQuestionAndAnswerArray]);
        }
    }, [bulkInsertQuestionAndAnswerArray])

    const cleanUpAfterSaving = (operation: string) => {
        setQuestionsAndAnswersInfo({
            question: '',
            answer: '',
            is_topic: false
        })

        setSnackbarMsg(`Questions And Answers Info has been ${operation}.`)
        showSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
    };

    const answerIsInParagraphText = () => {
        if (currentParagraphID) {
            return (paragraphs.find(p => p._id === currentParagraphID)?.textObj?.text!.toLowerCase().includes(questionsAndAnswersInfo.answer.toLowerCase()))
        } else {
            return (newParagraphText.toLowerCase().includes(questionsAndAnswersInfo.answer.toLowerCase()))
        }
    }

    const doValidation = () => {
        const result = ParagraphQuestionsAndAnswersSchema.safeParse(questionsAndAnswersInfo)

        if (!result.success) {
            highlightInvalidField(result.error.flatten().fieldErrors.answer, [refAnswer.current!], [setAnswerInputColor], setSnackbarMsg, setShowSnackbarMsg, showSnackbarMsgColor)
            highlightInvalidField(result.error.flatten().fieldErrors.question, [refQuestion.current!], [setQuestionInputColor], setSnackbarMsg, setShowSnackbarMsg, showSnackbarMsgColor)
            return
        }

        if (!editClicked) {
            const questionHasBeenAddedPreviously = (questionsAndAnswersInfoArray.filter(m => m.question.toLowerCase() === questionsAndAnswersInfo.question.trim().toLowerCase()).length) > 0

            if (questionHasBeenAddedPreviously) {
                setSnackbarMsg(`'${questionsAndAnswersInfo.question}' has already been added.`)
                setQuestionsAndAnswersInfo(prevState => ({
                    ...prevState,
                    question: ''
                }))
                refQuestion.current!.focus()
                showSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }

            const answerHasBeenAddedPreviously = (questionsAndAnswersInfoArray.filter(m => m.answer.toLowerCase() === questionsAndAnswersInfo.answer.trim()).length) > 0

            if (answerHasBeenAddedPreviously) {
                setSnackbarMsg(`'${questionsAndAnswersInfo.answer}' has already been added.`)
                setQuestionsAndAnswersInfo(prevState => ({
                    ...prevState,
                    answer: ''
                }))
                refAnswer.current!.focus()
                showSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }
        }

        const questionAndAnswerAreTheSame = (questionsAndAnswersInfo.question.trim().toLowerCase() === questionsAndAnswersInfo.answer.trim().toLowerCase())

        if (questionAndAnswerAreTheSame) {
            setSnackbarMsg('Question and Answer must be different.')
            setQuestionsAndAnswersInfo(prevState => ({
                ...prevState,
                answer: ''
            }))
            refAnswer.current!.focus()
            showSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        if (!answerIsInParagraphText()) {
            if (currentParagraphID) {
                setSnackbarMsg("Answer must be in the Paragraph Text")
            } else {
                setSnackbarMsg("Answer must be in the New Paragraph Text. Make sure you clicked the 'Save Paragraph Info' button.")
            }

            refAnswer.current!.focus()
            showSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        // Info is valid
        refQuestion.current!.focus()

        if (editClicked) {
            const updatedQuestionsAndAnswersInfoArray = [...questionsAndAnswersInfoArray];
            const updatedQandAInfo = {...questionsAndAnswersInfoArray[currentIndex]}

            setEditClicked(false)
            updatedQandAInfo.question = questionsAndAnswersInfo.question
            updatedQandAInfo.answer = questionsAndAnswersInfo.answer
            updatedQandAInfo.is_topic = questionsAndAnswersInfo.is_topic
            updatedQuestionsAndAnswersInfoArray.splice(currentIndex, 1, updatedQandAInfo);
            setQuestionsAndAnswersInfoArray(updatedQuestionsAndAnswersInfoArray)
            cleanUpAfterSaving('edited');
        } else {
            setQuestionsAndAnswersInfoArray([...questionsAndAnswersInfoArray, questionsAndAnswersInfo])
            cleanUpAfterSaving('added');
        }
    };

    const deleteQuestionsAndAnswers = () => {
        const updatedArray = [...questionsAndAnswersInfoArray];
        updatedArray.splice(currentIndex, 1);
        setQuestionsAndAnswersInfoArray(updatedArray);
        setShowDeleteConfirmation(false)
        setCurrentIndex(-1)
        refQuestion.current?.focus()
    };

    const editQandA = (indexToEdit: number) => {
        setCurrentIndex(indexToEdit)
        setEditClicked(true)
        setQuestionsAndAnswersInfo(questionsAndAnswersInfoArray[indexToEdit])
        refQuestion.current?.focus()
    };

    return (
        <List size="sm"
              sx={() => ({
                  'marginLeft': '20px',
                  'marginTop': '0px',
                  'marginBottom': '0px',
                  '--ListItem-paddingRight': '16px',
                  '--ListItem-paddingLeft': '21px',
                  '--ListItem-startActionTranslateX': '-50%',
              })}
        >
            <ListItem
                nested
                sx={{my: 0}}
                startAction={
                    <IconButton
                        variant="plain"
                        size="sm"
                        color="neutral"
                        onClick={() => setOpen(!open)}
                    >
                        <KeyboardArrowDown
                            sx={{transform: open ? 'initial' : 'rotate(-90deg)'}}
                        />
                    </IconButton>
                }
            >
                <ListItem>
                    <Typography
                        level="inherit"
                        sx={{
                            fontWeight: open ? 'bold' : undefined,
                            color: open ? 'text.primary' : 'inherit',
                        }}
                    >
                        Paragraph: Questions And Answers
                    </Typography>
                    <div style={{marginTop: '1px'}}>
                        {` ${questionsAndAnswersInfoArray.length}`}
                    </div>
                </ListItem>
                {open && (
                    <Box
                        sx={{
                            flex: 1,
                            p: 3,
                            boxShadow: 'lg',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Textarea color={questionInputColor as ColorPaletteProp}
                                  placeholder='Question *'
                                  style={{width: "100%"}}
                                  value={questionsAndAnswersInfo.question}
                                  slotProps={{
                                      textarea: {
                                          ref: refQuestion
                                      },
                                  }}
                                  onChange={(event) => {
                                      let textToEnter = event.target.value?.toLowerCase().startsWith("question:") ? event.target.value.slice(9).trim() : event.target.value

                                      textToEnter = event.target.value?.toLowerCase().startsWith("q:") ? event.target.value.slice(2).trim() : event.target.value

                                      textToEnter = textToEnter.replace(/^\n+/, '');  // Remove hard returns at the start of the text

                                      // Replace any occurrences of one or more newline characters (\n) followed by zero or more whitespace characters (\s*) followed by another newline character (\n) with a single newline character (\n). This effectively removes the hard return from the original string.
                                      textToEnter = textToEnter.replace(/\n\s*\n/g, '\n');

                                      // If the text entered has 1 or more hard returns, split up the text into an array and populate the Question, Answer.
                                      if (textToEnter.split('\n').length >= 1) {
                                          const [question, answer] = textToEnter.split('\n')

                                          setInputColor(question, setQuestionInputColor)

                                          setQuestionsAndAnswersInfo(prevState => ({
                                                  ...prevState,
                                                  question: question,
                                                  answer: getAnswerTextToEnter(answer)
                                              })
                                          )
                                      } else {
                                          setInputColor(textToEnter, setQuestionInputColor)
                                          setQuestionsAndAnswersInfo(prevState => ({
                                                  ...prevState,
                                                  question: textToEnter
                                              })
                                          )
                                      }
                                  }}
                        />
                        <Input color={answerInputColor as ColorPaletteProp}
                               fullWidth={true}
                               placeholder='Answer * (Must be in the Paragraph Text)'
                               value={questionsAndAnswersInfo.answer}
                               slotProps={{
                                   input: {
                                       ref: refAnswer
                                   },
                               }}
                               onChange={(event) => {
                                   setInputColor(event.target.value, setAnswerInputColor)
                                   setQuestionsAndAnswersInfo(prevState => ({
                                           ...prevState,
                                           answer: getAnswerTextToEnter(event.target.value)
                                       })
                                   )
                               }}
                        />
                        <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                            <Typography>
                                Include Answer in Topics Shown When 'Topics' is clicked on ModCard?
                            </Typography>
                            &nbsp;&nbsp;
                            <Checkbox
                                checked={questionsAndAnswersInfo.is_topic}
                                onClick={() => {
                                    setQuestionsAndAnswersInfo(prevState => ({
                                            ...prevState,
                                            is_topic: !prevState.is_topic
                                        })
                                    )
                                }
                                }
                            />
                        </div>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 3,
                            }}>
                            <Button size="lg"
                                    sx={{
                                        marginTop: "10px"
                                    }}
                                    onClick={
                                        () => doValidation()
                                    }
                            >
                                <Typography fontSize="large">Save Q and A Info</Typography>
                            </Button>
                            <Button size="lg"
                                    sx={{
                                        marginTop: "10px"
                                    }}
                                    onClick={
                                        () => {
                                            setEditClicked(false)
                                            setQuestionsAndAnswersInfo({question: '', answer: '', is_topic: false})
                                            refQuestion.current?.focus()
                                        }
                                    }
                            >
                                <Typography fontSize="large">Clear Q and A Info</Typography>
                            </Button>
                        </Box>
                        <div>
                            <Sheet sx={{height: 280, overflow: 'auto', marginLeft: '20px', marginRight: '20px'}}>
                                <Table
                                    aria-label="table with sticky header"
                                    stickyHeader
                                    stickyFooter
                                >
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Answer</th>
                                            <th>Topic?</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {questionsAndAnswersInfoArray.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.question}</td>
                                                <td>{row.answer}</td>
                                                <td>{row.is_topic ? 'true' : null}</td>
                                                <td>
                                                    <Box sx={{display: 'flex', gap: 1}}>
                                                        <Button size="sm" variant="soft" color="danger"
                                                                onClick={
                                                                    () => {
                                                                        editQandA(index)
                                                                    }
                                                                }>
                                                            Edit
                                                        </Button>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{display: 'flex', gap: 1}}>
                                                        <Button size="sm" variant="soft" color="danger"
                                                                onClick={
                                                                    () => {
                                                                        setCurrentIndex(index)
                                                                        setShowDeleteConfirmation(true)
                                                                    }
                                                                }>
                                                            Delete
                                                        </Button>
                                                    </Box>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={5} style={{textAlign: 'center'}}>
                                            Count: {questionsAndAnswersInfoArray.length}
                                        </td>
                                    </tr>
                                    </tfoot>
                                </Table>
                            </Sheet>
                        </div>
                        {
                            (() => {
                                if (showSnackbarMsg)
                                    return (
                                        <Snackbar
                                            size="lg"
                                            variant="solid"
                                            color={snackbarMsgColor as ColorPaletteProp}
                                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                            autoHideDuration={3000}
                                            open={true}
                                            onClose={() => {
                                                setShowSnackbarMsg(false);
                                            }}
                                            sx={{
                                                justifyContent: 'center',
                                                padding: '5px',
                                                whiteSpace: 'pre-line'
                                            }}
                                        >
                                            {snackbarMsg}
                                        </Snackbar>
                                    )
                            })()
                        }
                        {
                            (() => {
                                if (showDeleteConfirmation)
                                    return deleteConfirmationDialog('Q & A', setOpen, deleteQuestionsAndAnswers, setShowDeleteConfirmation)
                            })()
                        }
                    </Box>
                )}
            </ListItem>
        </List>
    )
}

export default QuestionsAndAnswers
