import React, {useEffect, useState} from 'react';
import {Header} from 'src/components/Header';
import {AssessmentResultFooter} from "src/components/Shared/AssessmentResultFooter";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {Box, List, ListItem} from '@mui/joy';
import Typography from "@mui/joy/Typography";
import {resetChapterAssessment} from "src/slices/chapterAssessmentSlice";
import {Page, update} from "src/slices/pageSlice";
import Button from "@mui/joy/Button";
import {resizeButtonSX, wordListPadding} from "src/utils/ui";
import {ChapterResult, useChapterMgr} from "src/hooks/useChapterMgr";
import {ModTypes} from "src/utils/constants";
import {completeChapterTest} from "src/utils/api-service";

export const ChapterAssessmentResult: React.FC = () => {
    const [currentQuestion, setCurrentQuestion] = useState('Click an Answer to see its corresponding Question')
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1)

    const title = useAppSelector((state: RootState) => state.chapter.retakeOfIncorrectAndSkippedAnswers ? `Incorrect and Skipped Answers of ${state.chapter.title}` : state.chapter.title);
    const results = useAppSelector((state: RootState) => state.chapterAssessment.data);
    const chapterTestID = useAppSelector((state: RootState) => state.chapter.chapterTestID);

    const appDispatch = useAppDispatch();
    const {assessmentIsValid, getChapterResult, getChapterResultCounts} = useChapterMgr();
    const resultCounts = getChapterResultCounts(results)

    const showQuestion = (index: number) => {
        setCurrentQuestion(results[index].question)
        setCurrentQuestionIndex(index)
    };

    useEffect(() => {
        completeChapterTest(chapterTestID,
            (response) => {
                if (response.data.message === 'Chapter Test Record Not Found') {
                    console.log(response.data.message)
                }
            },
            (error: any) => console.error("Error:", error)
        )
    }, []);

    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <Header/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: "center",
                    height: '10vh'
                }}
            >
                <Typography level="h1" fontWeight="lg" fontSize="xxx-large">
                    {title}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: 'center',
                    height: '7vh'
                }}
            >
                <Typography level="h1" fontWeight="lg" fontSize="xx-large">
                    Chapter Assessment Results
                </Typography>
            </Box>
            {
                (() => {
                    if (assessmentIsValid(results)) {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center'
                                }}
                            >
                                {wordListPadding()}
                                <Box
                                    sx={{
                                        width: '80%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        marginBottom: '40px'
                                    }}
                                >
                                    <Typography level="body-lg"
                                                padding="10px"
                                                sx={{
                                                    borderRadius: 'sm',
                                                    outline: '2px solid #2d792a'
                                                }}
                                    >
                                        {currentQuestion}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '80%',
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {results.map((result, index) => (
                                        <div key={index}>
                                            <List
                                                size='sm'
                                                variant="soft"
                                                sx={{
                                                    borderRadius: 'sm',
                                                    fontSize: 'lg',
                                                    outline: index === currentQuestionIndex ? '2px solid #2d792a' : null
                                                }}
                                            >
                                                <ListItem>
                                                    {
                                                        (() => {
                                                            if (getChapterResult(result) === ChapterResult.INCORRECT) {
                                                                return <div
                                                                    style={{cursor: 'pointer', display: 'flex'}}
                                                                    onClick={() => showQuestion(index)}
                                                                >
                                                                    <Typography
                                                                        color="danger">
                                                                        {result.incorrect_answer}
                                                                    </Typography>
                                                                    &nbsp;
                                                                    <Typography
                                                                        color="success">
                                                                        ({result.correct_answer})
                                                                    </Typography>
                                                                </div>;
                                                            }
                                                            if (getChapterResult(result) === ChapterResult.TYPO) {
                                                                return <div
                                                                    style={{cursor: 'pointer', display: 'flex'}}
                                                                    onClick={() => showQuestion(index)}
                                                                >
                                                                    <Typography
                                                                        color="danger">
                                                                        {result.typo_in_answer}
                                                                    </Typography>
                                                                    &nbsp;
                                                                    <Typography
                                                                        color="success">
                                                                        ({result.correct_answer})
                                                                    </Typography>
                                                                </div>;
                                                            }
                                                            if (getChapterResult(result) === ChapterResult.SKIPPED) {
                                                                return <div
                                                                    style={{cursor: "pointer", display: 'flex'}}
                                                                    onClick={() => showQuestion(index)}
                                                                >
                                                                    <Typography
                                                                        color="warning">
                                                                        skipped
                                                                    </Typography>
                                                                    &nbsp;
                                                                    <Typography
                                                                        color="success">
                                                                        ({result.correct_answer})
                                                                    </Typography>
                                                                </div>;
                                                            }
                                                            return (
                                                                <div
                                                                    style={{cursor: 'pointer'}}
                                                                    onClick={() => showQuestion(index)}
                                                                >
                                                                    <Typography color="success">
                                                                        {result.correct_answer}
                                                                    </Typography>
                                                                </div>
                                                            );
                                                        })()
                                                    }
                                                </ListItem>
                                            </List>
                                        </div>
                                    ))}
                                </Box>
                                {wordListPadding()}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "center",
                                        alignItems: 'center',
                                        height: '15vh'
                                    }}
                                >                                    {
                                    (() => {
                                        if (resultCounts[ChapterResult.INCORRECT] === 0 && resultCounts[ChapterResult.SKIPPED] === 0) {
                                            // All Answers were correct, but there may have been typos.
                                            return (
                                                <Button
                                                    sx={resizeButtonSX()}
                                                    variant="solid" color="primary"
                                                    onClick={
                                                        () => {
                                                            appDispatch(update(Page.HOME))
                                                        }
                                                    }
                                                >
                                                    Home
                                                </Button>
                                            )
                                        }

                                        // Some Questions were answered correctly or skipped
                                        return (
                                            <Button
                                                variant="solid" color="primary"
                                                sx={resizeButtonSX()}
                                                onClick={
                                                    () => {
                                                        appDispatch(update(Page.CHOOSE_CORRECT_ANSWER_IN_CHAPTER))
                                                    }
                                                }
                                            >
                                                {(resultCounts.incorrect + resultCounts.typo + resultCounts.skipped) === 1 ? 'Study Paragraph' : 'Study Paragraphs'}
                                            </Button>
                                        )
                                    })()
                                }
                                </Box>
                            </Box>
                        )
                    }

                    return (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    color="danger"
                                    fontSize="x-large"
                                >
                                    Not enough questions were answered to complete the assessment.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center',
                                    height: '15vh'
                                }}
                            >
                                <Button
                                    variant="solid" color="primary"
                                    sx={resizeButtonSX()}
                                    onClick={
                                        () => {
                                            appDispatch(resetChapterAssessment())
                                            appDispatch(update(Page.ANSWER_QUESTION))
                                        }
                                    }
                                >
                                    Restart the Assessment
                                </Button>
                            </Box>
                        </>
                    )
                })()
            }
            <AssessmentResultFooter correct={resultCounts[ChapterResult.CORRECT]}
                                    incorrect={resultCounts[ChapterResult.INCORRECT]}
                                    skipped={resultCounts[ChapterResult.SKIPPED]}
                                    type={ModTypes.chapter}
                                    typo={resultCounts[ChapterResult.TYPO]}
            />
        </div>
    );
}