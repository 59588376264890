import React, {useEffect, useRef, useState} from 'react';
import {z} from "zod";
import {TextInputColor} from "src/utils/constants";
import {Box, ColorPaletteProp, FormLabel, Snackbar, Textarea} from "@mui/joy";
import {highlightInvalidField, setInputColor} from "src/utils/functions";
import {useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";

export interface SaveParagraphInfo {
    paragraphSaved: boolean,
    saveParagraphInfo: (infoToSave: any) => void,
    saveParagraphTextInfoClicked: boolean,
    setSaveParagraphTextInfoClickedToFalse?: () => void
}

export const ParagraphTextSchema = z.object(
    {
        text: z.string().trim().min(1)
    }
)

export const ParagraphText: React.FC<SaveParagraphInfo> = ({
                                                               paragraphSaved,
                                                               saveParagraphTextInfoClicked,
                                                               saveParagraphInfo,
                                                               setSaveParagraphTextInfoClickedToFalse
                                                           }) => {
    const [paragraphTextInfo, setParagraphTextInfo] = useState<z.infer<typeof ParagraphTextSchema>>(
        {
            text: ''
        }
    )
    const [textInputColor, setTextInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, showSnackbarMsgColor] = useState("danger")

    const currentParagraphID = useAppSelector((state: RootState) => state.chapter.currentParagraphID);
    const paragraphs = useAppSelector((state: RootState) => state.chapter.paragraphs);
    const clearParagraphInfo = useAppSelector((state: RootState) => state.chapter.clearParagraphInfo);

    const refTextArea = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (paragraphSaved) {
            setParagraphTextInfo({text: ''})
            refTextArea.current!.focus()
        }
    }, [paragraphSaved]);

    useEffect(() => {
        if (currentParagraphID !== '') {
            setParagraphTextInfo({text: paragraphs.find(p => p._id === currentParagraphID)?.textObj?.text || ''})
        }
    }, [currentParagraphID]);

    useEffect(() => {
        if (saveParagraphTextInfoClicked) {
            setSaveParagraphTextInfoClickedToFalse!()

            const result = ParagraphTextSchema.safeParse(paragraphTextInfo)

            if (!result.success) {
                setParagraphTextInfo({text: ''})
                highlightInvalidField(result.error.flatten().fieldErrors.text, [refTextArea.current!], [setTextInputColor], setSnackbarMsg, setShowSnackbarMsg, showSnackbarMsgColor)
                return
            }

            saveParagraphInfo(paragraphTextInfo)
        }
    }, [saveParagraphTextInfoClicked])

    useEffect(() => {
        if (clearParagraphInfo) {
            setParagraphTextInfo({text: ''})
            refTextArea.current!.focus()
        }
    }, [clearParagraphInfo]);

    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    p: 2,
                    boxShadow: 'lg',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: .5
                }}
            >
                <FormLabel>Paragraph Text *</FormLabel>
                <Textarea color={textInputColor as ColorPaletteProp}
                          style={{width: "100%"}}
                          value={paragraphTextInfo.text}
                          maxRows={5}
                          slotProps={{textarea: {ref: refTextArea}}}
                          onChange={(event) => {
                              setInputColor(event.target.value, setTextInputColor)
                              setParagraphTextInfo({text: event.target.value})
                          }}
                />
            </Box>
            <Snackbar
                size="lg"
                variant="solid"
                color={snackbarMsgColor as ColorPaletteProp}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={3000}
                open={showSnackbarMsg}
                onClose={() => {
                    setShowSnackbarMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                    whiteSpace: 'pre-line'
                }}
            >
                {snackbarMsg}
            </Snackbar>
        </>
    )
}


export default ParagraphText
