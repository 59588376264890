import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {z} from "zod";
import {ParagraphTextSchema} from "src/components/Chapter/ParagraphText";
import {ParagraphMultipleChoiceSchema} from "src/components/Chapter/MultipleChoice";
import {ParagraphQuestionsAndAnswersSchema} from "src/components/Chapter/QuestionsAndAnswers";
import {IChapterMod} from "@backend/mongoose.gen";
import {ModTypes} from "src/utils/constants";
import shuffle from "lodash/shuffle";
import DarkTrafficLight from "src/images/dark_traffic_light.png"

export type Paragraph = {
    _id: string,
    textObj: z.infer<typeof ParagraphTextSchema>,
    multipleChoiceArray: z.infer<typeof ParagraphMultipleChoiceSchema>[],
    questionsAndAnswersArray: z.infer<typeof ParagraphQuestionsAndAnswersSchema>[]
}

export interface QuestionAndAnswer {
    para_id: string;
    qa_id: string;
    question: string;
    answer: string;
}

export interface ChapterState {
    chapterID: string,
    currentQuestionIndex: number,
    clearParagraphInfo: boolean,
    currentParagraphID: string,
    paragraphs: Paragraph[],
    existingChapterData: IChapterMod | null,
    title: string,
    retakeOfIncorrectAndSkippedAnswers: boolean,
    userAnswer: string,
    questionHasBeenRead: boolean,
    extractedQsandAs: QuestionAndAnswer[],
    assessmentCompletedBeforeStudying: boolean,
    chapterStudyID: string  // This is the MongoDB ObjectID returned when the '/study/chapter/start' API call is made,
    chapterTestID: string,  // This is the MongoDB ObjectID returned when the '/test/chapter/start' API call is made
    newParagraphText: string,
    bulkInsertMultipleChoiceArray: z.infer<typeof ParagraphMultipleChoiceSchema>[],
    bulkInsertQuestionAndAnswerArray: z.infer<typeof ParagraphQuestionsAndAnswersSchema>[],
    currentTrafficLight: string
}

const initialState: ChapterState = {
    chapterID: "",
    currentQuestionIndex: 0,
    title: "",
    clearParagraphInfo: false,
    currentParagraphID: '',
    paragraphs: [],
    existingChapterData: null,
    retakeOfIncorrectAndSkippedAnswers: false,
    userAnswer: "",
    questionHasBeenRead: false,
    extractedQsandAs: [],
    assessmentCompletedBeforeStudying: false,
    chapterStudyID: "",
    chapterTestID: "",
    newParagraphText: "",
    bulkInsertMultipleChoiceArray: [],
    bulkInsertQuestionAndAnswerArray: [],
    currentTrafficLight: DarkTrafficLight
}

export const chapterSlice = createSlice({
    name: ModTypes.chapter,
    initialState,
    reducers: {
        addParagraph(state, action: PayloadAction<Paragraph>) {
            const updatedParagraphs = [...state.paragraphs];

            updatedParagraphs.push({
                _id: action.payload._id,
                textObj: action.payload.textObj,
                multipleChoiceArray: action.payload.multipleChoiceArray,
                questionsAndAnswersArray: action.payload.questionsAndAnswersArray
            })

            state.paragraphs = updatedParagraphs
        },
        updateParagraph(state, action: PayloadAction<Paragraph>) {
            const updatedParagraphs = [...state.paragraphs];

            let indexToUpdate = updatedParagraphs.findIndex(paragraph => paragraph._id === action.payload._id);

            if (indexToUpdate !== -1) {
                updatedParagraphs[indexToUpdate] = {
                    ...updatedParagraphs[indexToUpdate],
                    textObj: action.payload.textObj,
                    multipleChoiceArray: action.payload.multipleChoiceArray,
                    questionsAndAnswersArray: action.payload.questionsAndAnswersArray
                };
            }

            state.paragraphs = updatedParagraphs
            state.currentParagraphID = ''
        },
        setCurrentParagraphID(state, action: PayloadAction<string>) {
            state.currentParagraphID = action.payload
        },
        setNewParagraphText(state, action: PayloadAction<string>) {
            state.newParagraphText = action.payload
        },
        clearParagraphInfoClicked(state, action: PayloadAction<boolean>) {
            state.clearParagraphInfo = action.payload
        },
        addChapter(state, action: PayloadAction<IChapterMod>) {
            state.existingChapterData = action.payload
            state.paragraphs = []
            state.currentParagraphID = ''

            action.payload.paragraphs.forEach(paragraph => {
                state.paragraphs.push({
                    _id: paragraph._id.toString(),
                    textObj: {text: paragraph.text},
                    multipleChoiceArray: paragraph.multiple_choices,
                    questionsAndAnswersArray: paragraph.questions_and_answers
                })
            })
        },
        saveSelectedChapterInfo(state, action: PayloadAction<{
            chapterID: string,
            title: string,
            paragraphs: Paragraph[],
            assessmentCompletedBeforeStudying: boolean
        }>) {
            state.chapterID = action.payload.chapterID;
            state.retakeOfIncorrectAndSkippedAnswers = false;
            state.title = action.payload.title;
            state.paragraphs = shuffle(action.payload.paragraphs);
            state.extractedQsandAs = shuffle(action.payload.paragraphs.flatMap((paragraph: any) => {
                return paragraph.questionsAndAnswersArray.map((qa: any) => {
                    return {
                        para_id: paragraph._id,
                        qa_id: qa._id.toString(),
                        question: qa.question,
                        answer: qa.answer
                    };
                });
            }));
            state.assessmentCompletedBeforeStudying = action.payload.assessmentCompletedBeforeStudying
        },
        incrementCurrentQuestionIndex(state) {
            state.currentQuestionIndex++;
        },
        resetCurrentQuestionIndex(state) {
            state.currentQuestionIndex = 0;
        },
        updateAnswer(state, action: PayloadAction<string>) {
            state.userAnswer = action.payload
        },
        setQuestionHasBeenRead(state, action: PayloadAction<boolean>) {
            state.questionHasBeenRead = action.payload;
        },
        saveIncorrectAndSkippedResultsInfo(state, action: PayloadAction<Paragraph[]>) {
            state.retakeOfIncorrectAndSkippedAnswers = true
            state.paragraphs = action.payload
        },
        saveChapterStudyID(state, action: PayloadAction<string>) {
            state.chapterStudyID = action.payload
        },
        saveChapterTestID(state, action: PayloadAction<string>) {
            state.chapterTestID = action.payload
        },
        saveBulkInsertMultipleChoiceArray(state, action: PayloadAction<z.infer<typeof ParagraphMultipleChoiceSchema>[]>) {
            state.bulkInsertMultipleChoiceArray = action.payload
        },
        saveBulkInsertQuestionAndAnswerArray(state, action: PayloadAction<z.infer<typeof ParagraphQuestionsAndAnswersSchema>[]>) {
            state.bulkInsertQuestionAndAnswerArray = action.payload
        },
        setCurrentTrafficLight(state, action: PayloadAction<string>) {
            state.currentTrafficLight = action.payload
        }
    }
})

export const {
    addParagraph,
    updateParagraph,
    setCurrentParagraphID,
    setNewParagraphText,
    clearParagraphInfoClicked,
    addChapter,
    saveSelectedChapterInfo,
    incrementCurrentQuestionIndex,
    resetCurrentQuestionIndex,
    updateAnswer,
    setQuestionHasBeenRead,
    saveIncorrectAndSkippedResultsInfo,
    saveChapterStudyID,
    saveChapterTestID,
    saveBulkInsertMultipleChoiceArray,
    saveBulkInsertQuestionAndAnswerArray,
    setCurrentTrafficLight
} = chapterSlice.actions

export default chapterSlice.reducer;