import React, {FC, MouseEvent, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {ChapterResult, CompareAnswerResult, useChapterMgr} from "src/hooks/useChapterMgr";
import {Header} from "src/components/Header";
import Typography from "@mui/joy/Typography";
import {
    Box,
    Input,
    Modal, ModalClose,
    Sheet,
    Snackbar,
    Tooltip
} from "@mui/joy";
import {Paragraph} from "src/slices/chapterSlice";
import Button from "@mui/joy/Button";
import {resizeButtonSX} from "src/utils/ui";
import {ActivityFooter} from "src/components/Spelling/ActiivityFooter";
import {useCountdown, useInterval} from "usehooks-ts";
import {Page, setAssessmentPageTitle, setAssessmentPageType, update} from "src/slices/pageSlice";
import {resetChapterAssessment} from "src/slices/chapterAssessmentSlice";
import {saveIncorrectAndSkippedResultsInfo} from "src/slices/chapterSlice";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import Title from "src/components/Shared/Title";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {ModTypes} from "src/utils/constants";
import {InfoOutlined} from "@mui/icons-material";
import {
    CurrentAudioComponent,
    resetCorrectAudioOnEnd,
    resetWrongAudioOnEnd,
    setCurrentAudioComponent
} from "src/slices/sharedSlice";
import {completeChapterStudy} from "src/utils/api-service";
import DarkTrafficLight from "src/images/dark_traffic_light.png"
import GreenTrafficLight from "src/images/green_traffic_light.png"
import RedTrafficLight from "src/images/red_traffic_light.png"
import YellowTrafficLight from "src/images/yellow_traffic_light.png"

// This component selects the incorrect and skipped answers,
// gets the corresponding Paragraphs by ID,
// then, for each paragraph, displays a question and
// the user has to type the correct answer.

// Paragraphs JSON
// [
//     {
//         "_id": "1",
//         "textObj": {
//             "text": "For Testing this is the number 54 answer. I like the color gray. Do you like it? Chris was born in Mobile. Beans and rice is nice."
//         },
//         "multipleChoiceArray": [
//             {
//                 "_id": "1",
//                 "question": "How old are you?",
//                 "answer": "54",
//                 "options": [
//                     "15",
//                     "9",
//                     "52"
//                 ]
//             },
//             {
//                 "_id": "2",
//                 "question": "What is your favorite color?",
//                 "answer": "grey",
//                 "options": [
//                     "blue",
//                     "reg",
//                     "green"
//                 ]
//             }
//         ],
//         "questionsAndAnswersArray": [
//             {
//                 "_id": "1",
//                 "question": "How old are you?",
//                 "answer": "54"
//             },
//             {
//                 "_id": "2",
//                 "question": "What is your favorite color?",
//                 "answer": "gray"
//             },
//             {
//                 "_id": "3",
//                 "question": "Where were you born?",
//                 "answer": "Mobile"
//             },
//             {
//                 "_id": "4",
//                 "question": "In what year were you born?",
//                 "answer": "1969"
//             },
//             {
//                 "_id": "5",
//                 "question": "What is nice?",
//                 "answer": "beans and rice"
//             }
//         ]
//     },
//     {
//         "_id": "2",
//         "textObj": {
//             "text": "New Paragraph Text about Heartfulness. The city that's the headquarters of heart is Hyderabad. 2 comes after 1."
//         },
//         "multipleChoiceArray": [
//             {
//                 "_id": "1",
//                 "question": "Where is Kanha?",
//                 "answer": "Hyderabad",
//                 "options": [
//                     "Chennai",
//                     "Charlotte",
//                     "Mumbai"
//                 ]
//             }
//         ],
//         "questionsAndAnswersArray": [
//             {
//                 "_id": "1",
//                 "question": "How do you spell 'Heart'",
//                 "answer": "heart"
//             },
//             {
//                 "_id": "2",
//                 "question": "What number comes after 1?",
//                 "answer": "2"
//             }
//         ]
//     }
// ]
export const TypeCorrectAnswerInChapter: FC = () => {
    const {answerIsCorrect, getChapterResult} = useChapterMgr();

    const paragraphs = useAppSelector((state: RootState) => state.chapter.paragraphs);
    const assessmentResults = useAppSelector((state: RootState) => state.chapterAssessment.data);
    const assessmentCompletedBeforeStudying = useAppSelector((state: RootState) => state.chapter.assessmentCompletedBeforeStudying)
    const title = useAppSelector((state: RootState) => state.chapter.title)

    const [incorrectAndSkippedResults] = useState(assessmentResults.filter(result => [ChapterResult.INCORRECT, ChapterResult.SKIPPED].includes(getChapterResult(result))))
    const [paragraphsToStudy, setParagraphsToStudy] = useState<Paragraph[]>([])
    const [currentParagraphToStudyIndex, setCurrentParagraphToStudyIndex] = useState(-1);
    const [question, setQuestion] = useState<string | undefined>('')
    const [answer, setAnswer] = useState<string | undefined>('')
    const [questionsAndAnswersArrayCtr, setQuestionsAndAnswersArrayCtr] = useState(-1)
    const [showWrongAnswerMsg, setShowWrongAnswerMsg] = useState(false);
    const [typedAnswer, setTypedAnswer] = useState('')
    const [showHint, setShowHint] = useState(false)
    const [hintCountdown, setHintCountdown] = useState(0)
    const [hintAnswer, setHintAnswer] = useState<string | undefined>('')
    const [wrongAnswerMsg, setWrongAnswerMsg] = useState('')
    const [submitButtonShouldBeDisabled, setSubmitButtonShouldBeDisabled] = useState(false)
    const [currentTrafficLight, setCurrentTrafficLight] = useState(DarkTrafficLight)

    const submitWhenEnterKeyIsPressed = useAppSelector((state: RootState) => state.settings.submitWhenEnterKeyIsPressed);

    const refTypedAnswer = useRef<HTMLInputElement>(null);

    const {playCorrectAudio, playWrongAudio, speak, stopSpeaking} = useSharedMgr();

    const currentAudioComponent = useAppSelector((state: RootState) => state.shared?.currentAudioComponent);
    const correctAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.correctAudioOnEndFired);
    const wrongAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.wrongAudioOnEndFired);

    const chapterStudyID = useAppSelector((state: RootState) => state.chapter.chapterStudyID);

    const appDispatch = useAppDispatch();

    const typoMsg = "You didn't spell the answer correctly, but you may proceed.";

    const [, {resetCountdown, startCountdown}] =
        useCountdown({
            countStart: hintCountdown,
            intervalMs: 1000,
            isIncrement: false,
            countStop: 0
        })

    useEffect(() => {
        if (assessmentCompletedBeforeStudying) {
            setParagraphsToStudy(paragraphs!.filter(item => [...new Set(incorrectAndSkippedResults.map(item => item.paragraph_id))].includes(item._id)).map(item => item))
        } else {
            setParagraphsToStudy(paragraphs!.map(item => item))
        }

        appDispatch(setCurrentAudioComponent(CurrentAudioComponent.TYPE_CORRECT_ANSWER_IN_CHAPTER))
    }, [])

    useEffect(() => {
        if (refTypedAnswer?.current && correctAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.TYPE_CORRECT_ANSWER_IN_CHAPTER) {
            setCurrentTrafficLight(DarkTrafficLight)
            refTypedAnswer.current.disabled = false
            setSubmitButtonShouldBeDisabled(false)
            setTypedAnswer('')
            setQuestionsAndAnswersArrayCtr(prevState => prevState + 1)
        }

        appDispatch(resetCorrectAudioOnEnd())
    }, [correctAudioOnEndFired])

    useEffect(() => {
        if (wrongAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.TYPE_CORRECT_ANSWER_IN_CHAPTER) {
            setCurrentTrafficLight(DarkTrafficLight)
            appDispatch(resetWrongAudioOnEnd())
        }
    }, [wrongAudioOnEndFired])

    useEffect(() => {
        if (currentParagraphToStudyIndex === -1) {
            return
        }

        if (currentParagraphToStudyIndex < paragraphsToStudy.length) {
            setQuestionsAndAnswersArrayCtr(0)
        }
    }, [currentParagraphToStudyIndex])

    useEffect(() => {
        if (questionsAndAnswersArrayCtr == -1 || currentParagraphToStudyIndex === -1 || currentParagraphToStudyIndex === paragraphsToStudy.length) {
            return
        }

        if (questionsAndAnswersArrayCtr === paragraphsToStudy[currentParagraphToStudyIndex].questionsAndAnswersArray.length) {
            // Show the next set of Questions and Answers from the next Paragraph
            setQuestionsAndAnswersArrayCtr(0)
            setCurrentParagraphToStudyIndex(currentParagraphToStudyIndex + 1)
            return
        }

        refTypedAnswer.current?.focus()
        setQuestion(paragraphsToStudy[currentParagraphToStudyIndex].questionsAndAnswersArray[questionsAndAnswersArrayCtr].question)
        setAnswer(paragraphsToStudy[currentParagraphToStudyIndex].questionsAndAnswersArray[questionsAndAnswersArrayCtr].answer)
    }, [questionsAndAnswersArrayCtr]);

    useEffect(() => {
        if (!showHint)
            refTypedAnswer.current?.focus()

    }, [showHint]);

    useEffect(() => {
        if (hintCountdown <= 0) {
            setHintAnswer('')
            setShowHint(false)
        }
    }, [hintCountdown]);

    useInterval(
        () => {
            setHintCountdown(prevState => prevState - 1000)
        },
        showHint ? 1000 : null
    )

    useEffect(() => {
        if (question !== '') {
            stopSpeaking()
            speak(question as string)
        }
    }, [question]);

    const handleStart_Click = () => {
        setCurrentParagraphToStudyIndex(0)
    }

    const handleSubmit_Click = () => {
        if (typedAnswer.trim() === '') {
            setCurrentTrafficLight(YellowTrafficLight)
            setWrongAnswerMsg('Please type the Answer.')
            setShowWrongAnswerMsg(true)
            playWrongAudio()
        } else {
            const answerIsCorrectResult: CompareAnswerResult = answerIsCorrect(paragraphsToStudy[currentParagraphToStudyIndex].questionsAndAnswersArray[questionsAndAnswersArrayCtr].answer, typedAnswer)

            if (answerIsCorrectResult === CompareAnswerResult.CORRECT) {
                setCurrentTrafficLight(GreenTrafficLight)
                setSubmitButtonShouldBeDisabled(true)
                refTypedAnswer.current!.disabled = true
                playCorrectAudio()
            } else {
                if (answerIsCorrectResult === CompareAnswerResult.TYPO) {
                    // The answer entered isn't spelled exactly the same as the correct answer, but it is close enough to allow the user to continue.
                    setCurrentTrafficLight(GreenTrafficLight)
                    refTypedAnswer.current!.disabled = true
                    setWrongAnswerMsg(typoMsg)
                    playCorrectAudio()
                } else {
                    setCurrentTrafficLight(RedTrafficLight)
                    setWrongAnswerMsg("That's not correct. Please try again.")
                    playWrongAudio()
                }

                setShowWrongAnswerMsg(true)
            }
        }

        refTypedAnswer.current?.focus()
    }

    const calculateHintTime = (inputString: string): void => {
        let count = 0;
        for (let i = 0; i < inputString.length; i += 5) {
            count += 2000;
        }
        setHintCountdown(count);
    };

    const transformAnswerIntoHint = (inputString: string): string | undefined => {
        const length = inputString.length;
        const charactersToReplace = Math.min(length, Math.floor((length - 1) / 5) + 1);
        const indicesToReplace = Array.from({length: charactersToReplace}, () =>
            Math.floor(Math.random() * length)
        );
        let modifiedString = inputString;
        indicesToReplace.forEach((index) => {
            modifiedString = modifiedString.substring(0, index) + '_' + modifiedString.substring(index + 1);
        });

        return modifiedString;
    };

    const handleRetryIncorrectAnswersClick = () => {
        const matchingIncorrectAndSkippedToResultsToStudy = paragraphs.filter(item => incorrectAndSkippedResults.some(match => match.paragraph_id === item._id))

        appDispatch(resetChapterAssessment())

        // This is needed so that only the incorrect and skipped paragraphs are shown for studying rather than all paragraphs.
        appDispatch(saveIncorrectAndSkippedResultsInfo(matchingIncorrectAndSkippedToResultsToStudy))
        appDispatch(update(Page.ANSWER_QUESTION))
    }

    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <Header/>
            <Title title={'Type the Correct Answer'}/>
            <Body>
                {
                    (() => {
                        if (currentParagraphToStudyIndex > -1 && currentParagraphToStudyIndex < paragraphsToStudy.length) {
                            return (
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 20
                                    }}>
                                    <Typography fontWeight="lg" fontSize="x-large">
                                        {question}
                                    </Typography>
                                    <Input placeholder='Type the Answer Here'
                                           value={typedAnswer} slotProps={{
                                        input: {
                                            ref: refTypedAnswer
                                        },
                                    }}
                                           onChange={(event) => {
                                               setTypedAnswer(event.target.value)
                                           }
                                           }
                                           onFocus={() => {
                                               if (typedAnswer.trim() === '') {
                                                   setTypedAnswer('')
                                               }
                                           }
                                           }
                                           onKeyDown={
                                               (event) => {
                                                   if (event.key === 'Enter' && typedAnswer.trim() !== '' && submitWhenEnterKeyIsPressed === 'yes') {
                                                       handleSubmit_Click();
                                                   }
                                               }
                                           }
                                    />
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 125,
                                            paddingTop: 10
                                        }}
                                        alt={
                                            'Traffic Light'
                                        }
                                        src={currentTrafficLight}
                                    />
                                </Box>
                            )
                        }

                        return (
                            (() => {
                                if (currentParagraphToStudyIndex > -1) {
                                    completeChapterStudy(chapterStudyID,
                                        (response) => {
                                            if (response.data.message === 'Chapter Study Record Not Found') {
                                                console.log(response.data.message)
                                            }
                                        },
                                        (error: any) => console.error("Error:", error)
                                    )

                                    return (
                                        <Typography fontWeight="lg" fontSize="xx-large">
                                            All Done
                                        </Typography>
                                    )
                                }
                            })()
                        )
                    })()
                }
            </Body>
            <ButtonRow>
                {
                    (() => {
                        if (currentParagraphToStudyIndex === -1) {
                            return (
                                <Button variant="solid" color="primary"
                                        sx={resizeButtonSX()}
                                        onClick={handleStart_Click}>Start</Button>
                            )
                        }

                        if (currentParagraphToStudyIndex < paragraphsToStudy.length) {
                            return (
                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                    <Button sx={resizeButtonSX()}
                                            onClick={() => {
                                                setHintAnswer(transformAnswerIntoHint(answer!))
                                                setShowHint(true)
                                                calculateHintTime(answer!)
                                                resetCountdown()
                                                startCountdown()
                                            }}>Show Hint</Button>
                                    <Button variant="solid" color="primary"
                                            sx={resizeButtonSX()}
                                            onClick={handleSubmit_Click}
                                            disabled={submitButtonShouldBeDisabled}
                                    >Submit</Button>
                                    <Button sx={resizeButtonSX()}
                                            onClick={() => {
                                                stopSpeaking()
                                                speak(question as string)
                                            }}>Read Sentence</Button>
                                </ButtonGroup>
                            )
                        }

                        if (assessmentCompletedBeforeStudying) {
                            // "Start" was clicked on Home Page
                            return (
                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                    <Tooltip title="Do the Whole Assessment Again" variant="solid">
                                        <Button sx={resizeButtonSX()} color="primary" onClick={() => {
                                            appDispatch(resetChapterAssessment())
                                            appDispatch(update(Page.ANSWER_QUESTION))
                                        }}>Restart</Button>
                                    </Tooltip>
                                    <Tooltip title="Do Only Incorrect Answers Again" variant="solid">
                                        <Button sx={resizeButtonSX()} variant="solid" color="primary"
                                                onClick={handleRetryIncorrectAnswersClick}>Retry</Button>
                                    </Tooltip>
                                    <Tooltip title="Go to the Home Page" variant="solid">
                                        <Button sx={resizeButtonSX()} color="primary" onClick={() =>
                                            appDispatch(update(Page.HOME))
                                        }>Home</Button>
                                    </Tooltip>
                                </ButtonGroup>
                            )
                        } else {
                            // "Study" was clicked on Home Page
                            return (
                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                    <Tooltip title="Redo the Study Session" variant="solid">
                                        <Button sx={resizeButtonSX()}
                                                onClick={
                                                    () => {
                                                        appDispatch(update(Page.CHOOSE_CORRECT_ANSWER_IN_CHAPTER))
                                                    }
                                                }>Study Again</Button>
                                    </Tooltip>
                                    <Tooltip title="Go to the Home Page">
                                        <Button sx={resizeButtonSX()} variant="solid" color="primary" onClick={() =>
                                            appDispatch(update(Page.HOME))
                                        }>Home</Button>
                                    </Tooltip>
                                    <Tooltip title="Start Chapter Assessment">
                                        <Button sx={resizeButtonSX()}
                                                onClick={() => {
                                                    appDispatch(update(Page.ASSESSMENT))
                                                    appDispatch(setAssessmentPageTitle(title))
                                                    appDispatch(setAssessmentPageType(ModTypes.chapter))
                                                }
                                                }>Start</Button>
                                    </Tooltip>
                                </ButtonGroup>
                            )
                        }
                    })()
                }
            </ButtonRow>
            <ActivityFooter currentIndex={currentParagraphToStudyIndex} totalCount={paragraphsToStudy.length}
                            description='Paragraphs'/>
            {
                (() => {
                    if (showWrongAnswerMsg) {
                        return (
                            <Snackbar
                                size="lg"
                                variant="solid"
                                color="warning"
                                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                autoHideDuration={wrongAnswerMsg === typoMsg ? 3000 : 1750}
                                open={true}
                                onClose={() => {
                                    if (wrongAnswerMsg === typoMsg) {
                                        setTypedAnswer('')
                                    }

                                    setShowWrongAnswerMsg(false);
                                }}
                                sx={{
                                    justifyContent: 'center',
                                    padding: '5px',
                                }}
                            >
                                {
                                    (() => {
                                        return wrongAnswerMsg
                                    })()
                                }
                            </Snackbar>
                        )
                    }
                })()
            }
            {
                (() => {
                    if (showHint) {
                        return (
                            <Modal
                                aria-labelledby="close-modal-title"
                                open={true}
                                onClose={(_event: MouseEvent<HTMLButtonElement>, reason: string) => {
                                    if (reason === 'closeClick') {
                                        setHintAnswer('')
                                        setShowHint(false);
                                    }
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        minWidth: 400,
                                        maxWidth: 600,
                                        borderRadius: 'md',
                                        p: 3,
                                        boxShadow: 'lg',
                                        overflow: 'auto',
                                        maxHeight: 500
                                    }}
                                >
                                    <ModalClose variant="plain" sx={{m: 1}}/>
                                    <Typography
                                        id="modal-title"
                                        level="h4"
                                        color="primary"
                                        fontWeight="lg"
                                        maxWidth="500px"
                                    >
                                        {`Hint (${(hintCountdown / 1000).toString()} ${hintCountdown > 1000 ? 'seconds' : 'second'})`}
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginTop: '15px',
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            level="h4"
                                        >
                                            {hintAnswer}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            marginTop: '15px',
                                        }}
                                    >
                                        <Typography
                                            variant="soft"
                                            color="primary"
                                            level="body-md"
                                            startDecorator={<InfoOutlined/>}
                                            sx={{alignItems: 'flex-start'}}
                                        >
                                            Each underscore ("_") represents a missing letter.
                                        </Typography>
                                    </Box>
                                </Sheet>
                            </Modal>
                        )
                    }
                })()}
        </div>
    )

}