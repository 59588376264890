import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    ColorPaletteProp,
    IconButton,
    Input,
    List,
    ListItem,
    Sheet,
    Snackbar,
    Table, Textarea
} from "@mui/joy";
import {getAnswerTextToEnter, setInputColor} from "src/utils/functions";
import {z} from "zod";
import {TextInputColor} from "src/utils/constants";
import {uniq} from "lodash";
import Typography from "@mui/joy/Typography";
import {KeyboardArrowDown} from "@mui/icons-material";
import {SaveParagraphInfo} from "src/components/Chapter/ParagraphText";
import {useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {deleteConfirmationDialog} from "src/components/Shared/DeleteModal";

export const ParagraphMultipleChoiceSchema = z.object(
    {
        question: z.string().trim().min(1),
        answer: z.string().trim().min(1),
        options: z.array(z.string().trim().min(1)).min(3).max(3)
    }
)
export const MultipleChoice: FC<SaveParagraphInfo> = ({
                                                          paragraphSaved,
                                                          saveParagraphTextInfoClicked,
                                                          saveParagraphInfo
                                                      }) => {
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, showSnackbarMsgColor] = useState("neutral")
    const [multipleChoiceInfoArray, setMultipleChoiceInfoArray] = useState<z.infer<typeof ParagraphMultipleChoiceSchema>[]>([])
    const [multipleChoiceInfo, setMultipleChoiceInfo] = useState<z.infer<typeof ParagraphMultipleChoiceSchema>>(
        {
            question: '',
            answer: '',
            options: ['', '', '']
        }
    )
    const [questionInputColor, setQuestionInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [answerInputColor, setAnswerInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [option1InputColor, setOption1InputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [option2InputColor, setOption2InputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [option3InputColor, setOption3InputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [editClicked, setEditClicked] = useState(false)

    const currentParagraphID = useAppSelector((state: RootState) => state.chapter.currentParagraphID);
    const paragraphs = useAppSelector((state: RootState) => state.chapter.paragraphs);
    const clearParagraphInfo = useAppSelector((state: RootState) => state.chapter.clearParagraphInfo);
    const bulkInsertMultipleChoiceArray = useAppSelector((state: RootState) => state.chapter.bulkInsertMultipleChoiceArray);

    const refQuestion = useRef<HTMLTextAreaElement | null>(null);
    const refAnswer = useRef<HTMLInputElement | null>(null);
    const refOption1 = useRef<HTMLInputElement | null>(null);
    const refOption2 = useRef<HTMLInputElement | null>(null);
    const refOption3 = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (saveParagraphTextInfoClicked) {
            if (multipleChoiceInfoArray.length === 0) {
                return
            }

            saveParagraphInfo(multipleChoiceInfoArray)
        }
    }, [saveParagraphTextInfoClicked])

    useEffect(() => {
        if (currentParagraphID !== '') {
            let tempMultipleChoiceArray = paragraphs.find(p => p._id === currentParagraphID)?.multipleChoiceArray

            setMultipleChoiceInfo({
                question: '',
                answer: '',
                options: ['', '', '']
            })
            setMultipleChoiceInfoArray(tempMultipleChoiceArray as z.infer<typeof ParagraphMultipleChoiceSchema>[])
        }
    }, [currentParagraphID]);

    useEffect(() => {
        if (paragraphSaved) {
            setMultipleChoiceInfoArray([])
        }
    }, [paragraphSaved]);

    useEffect(() => {
        if (clearParagraphInfo) {
            setMultipleChoiceInfoArray([])
        }
    }, [clearParagraphInfo]);

    useEffect(() => {
        if (bulkInsertMultipleChoiceArray?.length > 0) {
            setMultipleChoiceInfoArray([...multipleChoiceInfoArray, ...bulkInsertMultipleChoiceArray]);
        }
    }, [bulkInsertMultipleChoiceArray])

    const checkForQuestionAnswerOptionsMatch = (questionOrAnswer: string) => {
        const trimmedQuestionOrAnswer = questionOrAnswer.trim().toLowerCase();
        const nonEmptyOptions = multipleChoiceInfo.options.filter(item => item.trim() !== '').map(item => item.toLowerCase());
        const matchingIndex = nonEmptyOptions.findIndex(option => option === trimmedQuestionOrAnswer);

        if (matchingIndex !== -1) {
            setSnackbarMsg(`Option has already been entered as the ${questionOrAnswer === multipleChoiceInfo.answer ? 'Answer' : 'Question'}.`);
            const updatedOptions = [...multipleChoiceInfo.options];
            updatedOptions[matchingIndex] = ''; // make matching option empty
            setMultipleChoiceInfo(prevState => ({
                ...prevState,
                options: updatedOptions
            }));

            if (matchingIndex === 0) {
                refOption1.current!.focus();
            } else if (matchingIndex === 1) {
                refOption2.current!.focus();
            } else {
                refOption3.current!.focus();
            }

            showSnackbarMsgColor('danger');
            setShowSnackbarMsg(true);
            return true; // indicate match found
        }
        return false; // indicate no match found
    }

    const cleanUpAfterSaving = (operation: string) => {
        setMultipleChoiceInfo({
            question: '',
            answer: '',
            options: ['', '', '']
        })
        setOption1InputColor(TextInputColor.NEUTRAL)
        setOption2InputColor(TextInputColor.NEUTRAL)
        setOption3InputColor(TextInputColor.NEUTRAL)

        setSnackbarMsg(`Multiple Choice Info has been ${operation}.`)
        showSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
    };

    const doValidation = () => {
        const result = ParagraphMultipleChoiceSchema.safeParse(multipleChoiceInfo)

        if (!result.success) {
            highlightInvalidField(result.error.flatten().fieldErrors.options, [refOption1.current!, refOption2.current!, refOption3.current!], [setOption1InputColor, setOption2InputColor, setOption3InputColor])
            highlightInvalidField(result.error.flatten().fieldErrors.answer, [refAnswer.current!], [setAnswerInputColor])
            highlightInvalidField(result.error.flatten().fieldErrors.question, [refQuestion.current!], [setQuestionInputColor])
            return
        }

        const duplicateOptionsFound = uniq(multipleChoiceInfo.options.filter((item) => item.trim() !== '').map((item) => item.toLowerCase())).length < multipleChoiceInfo.options.length;

        if (duplicateOptionsFound) {
            setSnackbarMsg('Duplicate options are not allowed.')
            const option1 = multipleChoiceInfo.options[0].trim()
            const option2 = multipleChoiceInfo.options[1].trim()
            const option3 = multipleChoiceInfo.options[2].trim()

            if (option1.toLowerCase() === option2.toLowerCase() && option2.toLowerCase() === option3.toLowerCase()) {
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    options: [option1, '', '']
                }))
                setOption2InputColor(TextInputColor.DANGER)
                refOption2.current!.focus()
                setOption3InputColor(TextInputColor.DANGER)
            } else if (option1.toLowerCase() === option2.toLowerCase()) {
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    options: [option1, '', option3]
                }))
                setOption2InputColor(TextInputColor.DANGER)
                refOption2.current!.focus()
            } else if (option1.toLowerCase() === option3.toLowerCase()) {
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    options: [option1, option2, '']
                }))
                setOption3InputColor(TextInputColor.DANGER)
                refOption3.current!.focus()
            } else if (option2.toLowerCase() === option3.toLowerCase()) {
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    options: [option1, option2, '']
                }))
                setOption3InputColor(TextInputColor.DANGER)
                refOption3.current!.focus()
            }

            showSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        if (!editClicked) {
            const questionHasBeenAddedPreviously = (multipleChoiceInfoArray.filter(m => m.question.toLowerCase() === multipleChoiceInfo.question.trim().toLowerCase()).length) > 0

            if (questionHasBeenAddedPreviously) {
                setSnackbarMsg(`'${multipleChoiceInfo.question}' has already been added.`)
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    question: ''
                }))
                refQuestion.current!.focus()
                showSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }

            const answerHasBeenAddedPreviously = (multipleChoiceInfoArray.filter(m => m.answer.toLowerCase() === multipleChoiceInfo.answer.trim()).length) > 0

            if (answerHasBeenAddedPreviously) {
                setSnackbarMsg(`'${multipleChoiceInfo.answer}' has already been added.`)
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    answer: ''
                }))
                refAnswer.current!.focus()
                showSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }
        }

        const questionAndAnswerAreTheSame = (multipleChoiceInfo.question.trim().toLowerCase() === multipleChoiceInfo.answer.trim().toLowerCase())

        if (questionAndAnswerAreTheSame) {
            setSnackbarMsg('Question and Answer must be different.')
            setMultipleChoiceInfo(prevState => ({
                ...prevState,
                answer: ''
            }))
            refAnswer.current!.focus()
            showSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        if (checkForQuestionAnswerOptionsMatch(multipleChoiceInfo.question)) {
            return
        }

        if (checkForQuestionAnswerOptionsMatch(multipleChoiceInfo.answer)) {
            return
        }

        // Info is valid
        refQuestion.current!.focus()

        if (editClicked) {
            const updatedMultipleChoiceInfoArray = [...multipleChoiceInfoArray];
            const updatedMultipleChoiceInfo = {...multipleChoiceInfoArray[currentIndex]}

            setEditClicked(false)
            updatedMultipleChoiceInfo.question = multipleChoiceInfo.question
            updatedMultipleChoiceInfo.answer = multipleChoiceInfo.answer
            updatedMultipleChoiceInfo.options = multipleChoiceInfo.options
            updatedMultipleChoiceInfoArray.splice(currentIndex, 1, updatedMultipleChoiceInfo);
            setMultipleChoiceInfoArray(updatedMultipleChoiceInfoArray)
            cleanUpAfterSaving('edited');
        } else {
            setMultipleChoiceInfoArray([...multipleChoiceInfoArray, multipleChoiceInfo])
            cleanUpAfterSaving('added');
        }
    };

    const highlightInvalidField = (fieldName: string[] | undefined, inputElem: HTMLInputElement[] | HTMLTextAreaElement[], setInputColorAction: Dispatch<SetStateAction<TextInputColor>>[]) => {
        if (fieldName !== undefined) {
            setSnackbarMsg(fieldName[0])

            if (inputElem.length === 1) {
                inputElem[0].focus()
                setSnackbarMsg(fieldName[0])
                setInputColorAction[0](TextInputColor.DANGER)
            } else {
                // Options Array
                const option1 = inputElem[0].value.trim()
                const option2 = inputElem[1].value.trim()
                const option3 = inputElem[2].value.trim()

                if (fieldName.length === 3) {
                    // All Options are invalid.
                    setInputColorAction[0](TextInputColor.DANGER)
                    setInputColorAction[1](TextInputColor.DANGER)
                    setInputColorAction[2](TextInputColor.DANGER)
                    inputElem[0].focus()
                } else if (fieldName.length === 2) {
                    // 2 Options are invalid.
                    if (option1 !== '') {
                        // Error must be on the second and third options
                        setInputColorAction[1](TextInputColor.DANGER)
                        setInputColorAction[2](TextInputColor.DANGER)
                        inputElem[1].focus()
                    } else if (option2 !== '') {
                        // Error must be on the first and third options
                        setInputColorAction[0](TextInputColor.DANGER)
                        setInputColorAction[2](TextInputColor.DANGER)
                        inputElem[0].focus()
                    } else if (option3 !== '') {
                        // Error must be on the first and second options
                        setInputColorAction[0](TextInputColor.DANGER)
                        setInputColorAction[1](TextInputColor.DANGER)
                        inputElem[0].focus()
                    }
                } else if (fieldName.length === 1) {
                    // 1 Option is invalid.
                    if (option1 === '') {
                        setInputColorAction[0](TextInputColor.DANGER)
                        inputElem[0].focus()
                    } else if (option2 === '') {
                        setInputColorAction[1](TextInputColor.DANGER)
                        inputElem[1].focus()
                    } else if (option3 === '') {
                        setInputColorAction[2](TextInputColor.DANGER)
                        inputElem[2].focus()
                    } else {
                        // All options are present, but an option has been entered twice.
                        // Highlight the duplicated entries.
                        if (option1 === option2 && option1 === option3) {
                            setInputColorAction[0](TextInputColor.DANGER)
                            setInputColorAction[1](TextInputColor.DANGER)
                            setInputColorAction[2](TextInputColor.DANGER)
                            inputElem[0].focus()
                        } else if (option1 === option2) {
                            setInputColorAction[0](TextInputColor.DANGER)
                            setInputColorAction[1](TextInputColor.DANGER)
                            inputElem[0].focus()
                        } else if (option1 === option3) {
                            setInputColorAction[0](TextInputColor.DANGER)
                            setInputColorAction[2](TextInputColor.DANGER)
                            inputElem[0].focus()
                        } else if (option2 === option3) {
                            setInputColorAction[1](TextInputColor.DANGER)
                            setInputColorAction[2](TextInputColor.DANGER)
                            inputElem[1].focus()
                        }
                    }
                }
            }

            showSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
        }
    }

    const deleteChoice = () => {
        const updatedArray = [...multipleChoiceInfoArray];
        updatedArray.splice(currentIndex, 1);
        setMultipleChoiceInfoArray(updatedArray);
        setShowDeleteConfirmation(false)
        setCurrentIndex(-1)
        refQuestion.current?.focus()
    };

    const editChoice = (indexToEdit: number) => {
        setCurrentIndex(indexToEdit)
        setEditClicked(true)
        setMultipleChoiceInfo(multipleChoiceInfoArray[indexToEdit])
        refQuestion.current?.focus()
    };

    return (
        <List size="sm"
              sx={() => ({
                  'marginLeft': '20px',
                  'marginTop': '0px',
                  'marginBottom': '0px',
                  '--ListItem-paddingRight': '16px',
                  '--ListItem-paddingLeft': '21px',
                  '--ListItem-startActionTranslateX': '-50%',
              })}
        >
            <ListItem
                nested
                sx={{my: 0}}
                startAction={
                    <IconButton
                        variant="plain"
                        size="sm"
                        color="neutral"
                        onClick={() => setOpen(!open)}
                    >
                        <KeyboardArrowDown
                            sx={{transform: open ? 'initial' : 'rotate(-90deg)'}}
                        />
                    </IconButton>
                }
            >
                <ListItem>
                    <Typography
                        level="inherit"
                        sx={{
                            fontWeight: open ? 'bold' : undefined,
                            color: open ? 'text.primary' : 'inherit',
                        }}
                    >
                        Paragraph: Multiple Choice
                    </Typography>
                    <div style={{marginTop: '1px'}}>
                        {` ${multipleChoiceInfoArray.length}`}
                    </div>
                </ListItem>
                {open && (
                    <Box
                        sx={{
                            flex: 1,
                            p: 3,
                            boxShadow: 'lg',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Textarea color={questionInputColor as ColorPaletteProp}
                                  placeholder='Question *'
                                  style={{width: "100%"}}
                                  value={multipleChoiceInfo.question}
                                  slotProps={{
                                      textarea: {ref: refQuestion}
                                  }}
                                  onChange={(event) => {
                                      let textToEnter = event.target.value.toLowerCase().startsWith("question:") ? event.target.value.slice(9).trim() : event.target.value

                                      textToEnter = event.target.value.toLowerCase().startsWith("q:") ? event.target.value.slice(2).trim() : event.target.value

                                      textToEnter = textToEnter.replace(/^\n+/, '');  // Remove hard returns at the start of the text

                                      // Replace any occurrences of one or more newline characters (\n) followed by zero or more whitespace characters (\s*) followed by another newline character (\n) with a single newline character (\n). This effectively removes the hard return from the original string.
                                      textToEnter = textToEnter.replace(/\n\s*\n/g, '\n');

                                      // If the text entered has 4 or more hard returns, split up the text into an array and populate the Question, Answer and 3 Options.
                                      if (textToEnter.split('\n').length >= 4) {
                                          const [question, answer, option1, option2, option3] = textToEnter.split('\n')

                                          setInputColor(question, setQuestionInputColor)
                                          setMultipleChoiceInfo(prevState => ({
                                                  ...prevState,
                                                  question: question,
                                                  answer: answer,
                                                  options: [option1, option2, option3]
                                              })
                                          )
                                      } else {
                                          setInputColor(textToEnter, setQuestionInputColor)
                                          setMultipleChoiceInfo(prevState => ({
                                                  ...prevState,
                                                  question: textToEnter
                                              })
                                          )
                                      }
                                  }}
                        />
                        <Input color={answerInputColor as ColorPaletteProp}
                               fullWidth={true}
                               placeholder='Answer *'
                               value={multipleChoiceInfo.answer}
                               slotProps={{
                                   input: {
                                       ref: refAnswer
                                   },
                               }}
                               onChange={(event) => {
                                   setInputColor(event.target.value, setAnswerInputColor)
                                   setMultipleChoiceInfo(prevState => ({
                                           ...prevState,
                                           answer: getAnswerTextToEnter(event.target.value)
                                       })
                                   )
                               }}
                        />
                        <Input color={option1InputColor as ColorPaletteProp}
                               fullWidth={true}
                               placeholder='Option 1 *'
                               value={multipleChoiceInfo.options[0]}
                               slotProps={{
                                   input: {
                                       ref: refOption1
                                   },
                               }}
                               onChange={(event) => {
                                   setInputColor(event.target.value, setOption1InputColor)

                                   // Split the options and populate all 3 when a comma is in the text. Example "Rivers, valleys, and insects"
                                   let optionsArray = event.target.value.split(",")

                                   if (optionsArray.length === 3) {
                                       let option3 = optionsArray[2].toLowerCase().trim().startsWith("and") ? optionsArray[2].trim().slice(3).trim() : optionsArray[2].trim()

                                       option3 = option3.endsWith('.') ? option3.slice(0, -1) : option3

                                       setMultipleChoiceInfo(prevState => ({
                                               ...prevState,
                                               options: [optionsArray[0].trim(), optionsArray[1].trim(), option3]
                                           })
                                       )
                                   } else {
                                       setMultipleChoiceInfo(prevState => ({
                                               ...prevState,
                                               options: [event.target.value, prevState.options[1], prevState.options[2]]
                                           })
                                       )
                                   }
                               }}
                        />
                        <Input color={option2InputColor as ColorPaletteProp}
                               fullWidth={true}
                               placeholder='Option 2 *'
                               value={multipleChoiceInfo.options[1]}
                               slotProps={{
                                   input: {
                                       ref: refOption2
                                   },
                               }}
                               onChange={(event) => {
                                   setInputColor(event.target.value, setOption2InputColor)
                                   setMultipleChoiceInfo(prevState => ({
                                           ...prevState,
                                           options: [prevState.options[0], event.target.value, prevState.options[2]]
                                       })
                                   )
                               }}
                        />
                        <Input color={option3InputColor as ColorPaletteProp}
                               fullWidth={true}
                               placeholder='Option 3 *'
                               value={multipleChoiceInfo.options[2]}
                               slotProps={{
                                   input: {
                                       ref: refOption3
                                   },
                               }}
                               onChange={(event) => {
                                   setInputColor(event.target.value, setOption3InputColor)
                                   setMultipleChoiceInfo(prevState => ({
                                           ...prevState,
                                           options: [prevState.options[0], prevState.options[1], event.target.value]
                                       })
                                   )
                               }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 3,
                            }}>
                            <Button size="lg"
                                    sx={{
                                        marginTop: "10px"
                                    }}
                                    onClick={
                                        () => doValidation()
                                    }
                            >
                                <Typography fontSize="large">Save Multiple Choice Info</Typography>
                            </Button>
                            <Button size="lg"
                                    sx={{
                                        marginTop: "10px"
                                    }}
                                    onClick={
                                        () => {
                                            setEditClicked(false)
                                            setMultipleChoiceInfo({question: '', answer: '', options: ['', '', '']})
                                            refQuestion.current?.focus()
                                        }
                                    }
                            >
                                <Typography fontSize="large">Clear Multiple Choice Info</Typography>
                            </Button>
                        </Box>
                        <div>
                            <Sheet sx={{height: 280, overflow: 'auto', marginLeft: '20px', marginRight: '20px'}}>
                                <Table
                                    aria-label="table with sticky header"
                                    stickyHeader
                                    stickyFooter
                                >
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Answer</th>
                                            <th>Option 1</th>
                                            <th>Option 2</th>
                                            <th>Option 3</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {multipleChoiceInfoArray.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.question}</td>
                                                <td>{row.answer}</td>
                                                <td>{row.options[0]}</td>
                                                <td>{row.options[1]}</td>
                                                <td>{row.options[2]}</td>
                                                <td>
                                                    <Box sx={{display: 'flex', gap: 1}}>
                                                        <Button size="sm" variant="soft" color="danger"
                                                                onClick={
                                                                    () => {
                                                                        editChoice(index)
                                                                    }
                                                                }>
                                                            Edit
                                                        </Button>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{display: 'flex', gap: 1}}>
                                                        <Button size="sm" variant="soft" color="danger"
                                                                onClick={
                                                                    () => {
                                                                        setCurrentIndex(index)
                                                                        setShowDeleteConfirmation(true)
                                                                    }
                                                                }>
                                                            Delete
                                                        </Button>
                                                    </Box>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={7} style={{textAlign: 'center'}}>
                                            Count: {multipleChoiceInfoArray.length}
                                        </td>
                                    </tr>
                                    </tfoot>
                                </Table>
                            </Sheet>
                        </div>
                        {
                            (() => {
                                if (showSnackbarMsg)
                                    return (
                                        <Snackbar
                                            size="lg"
                                            variant="solid"
                                            color={snackbarMsgColor as ColorPaletteProp}
                                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                            autoHideDuration={3000}
                                            open={true}
                                            onClose={() => {
                                                setShowSnackbarMsg(false);
                                            }}
                                            sx={{
                                                justifyContent: 'center',
                                                padding: '5px',
                                                whiteSpace: 'pre-line'
                                            }}
                                        >
                                            {snackbarMsg}
                                        </Snackbar>
                                    )
                            })()
                        }
                        {
                            (() => {
                                if (showDeleteConfirmation)
                                    return deleteConfirmationDialog('Choice', setOpen, deleteChoice, setShowDeleteConfirmation)
                            })()
                        }
                    </Box>
                )}
            </ListItem>
        </List>
    )
}

export default MultipleChoice